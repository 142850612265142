import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    useToast,
    HStack,
    Text,
    Box,
    Button,
    Spacer,
    Select,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useContext, useState, useRef } from "react";
import { useMediaCapture, StreamVideo, BlobVideo } from "react-media-capture";

import { AuthContext } from "../providers/AuthProvider.jsx";
import { DialogueContext } from "../providers/DialogueProvider.jsx";

import { API_URLS } from "../Constants.jsx";
import { constrainSize } from "../utils/utils.jsx";

const VideoDialogBox = ({}) => {
    const {
        status,
        liveVideo,
        capturedVideo,
        devices,
        duration,
        volume,
        selectedDeviceId,
        lastError,
        record,
        pause,
        resume,
        stop,
        clear,
        selectDevice,
    } = useMediaCapture();
    // const classNames = ["video-viewport", status];
    const size = constrainSize(liveVideo, { width: 480, height: 360 });

    const {
        setContent,
        feelingData,
        emotion,
        content,
    } = useContext(DialogueContext);
    const navigate = useNavigate();
    const [cookies] = useCookies();
    const toast = useToast();
    const { cookieAlive } = useContext(AuthContext);
    const [makeTimeEllapsed, setMakeTimeEllapsed] = useState(0);
    const [limitTime, setLimitData] = useState(0);
    const [sortedAlarms, setSortedAlarms] = useState([]);
    const [isPause, setIsPause] = useState(false);
    const isMounted = useRef(true);
    const timerRef = useRef(null);
    const location = useLocation();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const onStart = useCallback(() => {
        setMakeTimeEllapsed(0);
        const options = {
            videoMIMEType: "video/webm",
            videoBitsPerSecond: 2500000,
        };
        record(options);

        // Start the timer event if not already running
        if (limitTime > 0 && !timerRef.current) {
            if (!isPause) {
                const interval = setInterval(() => {
                    setMakeTimeEllapsed((prevTime) => {
                        if (prevTime >= limitTime) {
                            clearInterval(interval);

                            if (isMounted.current) {
                                stop();

                                setTimeout(() => {
                                    toast({
                                        title: "Time's up!",
                                        description:
                                            "You have reached the deadline.",
                                        duration: 3000,
                                        isClosable: true,
                                        bg: "blue",
                                        color: "white",
                                    });
                                }, 0);
                            }

                            return limitTime;
                        }

                        const newTime = prevTime + 1;
                        if (sortedAlarms.includes(newTime)) {
                            const audio = new Audio("./audio/alarm.mp3");
                            audio.play();
                        }

                        return newTime;
                    });
                }, 1000);
                timerRef.current = interval;
            } else if (timerRef.current) {
                clearInterval(timerRef.current);
            }

            return () => clearInterval(timerRef.current);
        }
    }, [record, limitTime, sortedAlarms, toast, stop]);

    // Save the video
    const handleSaveVideo = async () => {
        
        const formData = new FormData();
        formData.append("video", capturedVideo.blob, "video.webm");
        formData.append("appointmentId", location.state.apptId);
        formData.append("appointmentDate", location.state.apptDate);
        formData.append("userId", cookies["dd_user_id"]);
        formData.append("userEmail", cookies["dd_user_email"]);
        formData.append("spouseEmail", cookies["dd_spouse_email"]);
        formData.append("emotion", emotion);
        formData.append("feelings", JSON.stringify(feelingData));

        try {
            const response = await axios.post(
                API_URLS.DIALOGUE_ADD_VIDEO,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.success) {
                setContent(
                    `${process.env.REACT_APP_UPLOAD_URL}${response.data.path}`
                );
                toast({
                    title: "Dialogue Video",
                    description:
                        "Your video has been uploaded successfully. You can check in upcoming list.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                onClose();
            } else {
                toast({
                    title: "Dialogue Video",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        } catch (e) {
            toast({
                title: "Dialogue Video",
                description: `${e.message}.`,
                bg: "blue",
                color: "white",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        }
    };

    const onPause = useCallback(() => {
        pause();
        setIsPause(true);
    }, [pause]);

    const onResume = useCallback(() => {
        resume();
        setIsPause(false);
    }, [resume]);

    const onStop = useCallback(() => {
        stop();

        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [stop]);

    const onRetake = useCallback(() => clear(), [clear]);

    const onAccept = useCallback(() => {
        onOpen();
    }, []);

    useEffect(() => {
        if (lastError) {
            if (lastError.message.includes("Requested device not found")) {
                toast({
                    title: "Video",
                    description:
                        "The selected Video device is not available. Please check your device settings.",
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Video",
                    description:
                        typeof lastError === "string"
                            ? lastError
                            : "An unknown error occurred.",
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }, [lastError, toast]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!location.state.apptId) {
            navigate("/");
        }
    }, [navigate]);

    // Get about the time information
    useEffect(() => {
        const loadTimers = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.TIMERS_INFO, {
                    email: cookieAlive(),
                });

                if (response.data.success) {
                    const timers = response.data.data;

                    if (timers.video_time) {
                        setLimitData(parseInt(timers.video_time) * 60);
                    }

                    if (timers.video_alarm && timers.video_alarm.length > 0) {
                        const sortedAlarms = timers.video_alarm.sort((a, b) => {
                            const timeA = a.unit === "m" ? a.time * 60 : a.time;
                            const timeB = b.unit === "m" ? b.time * 60 : b.time;
                            return timeA - timeB;
                        });

                        let tempSortedAlarmData = [];
                        for (let i = 0; i < sortedAlarms.length; i++) {
                            if (sortedAlarms[i].enabled === true) {
                                if (sortedAlarms[i].unit === "s") {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time
                                    );
                                } else {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time * 60
                                    );
                                }
                            }
                        }

                        setSortedAlarms(tempSortedAlarmData);
                    }
                } else {
                    if (isMounted.current) {
                        toast({
                            title: "Timer Information",
                            description: `${response.data.message}.`,
                            bg: "blue",
                            color: "white",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadTimers();
    }, [cookieAlive, toast]);

    return (
        <Box>
            <AlertDialog isOpen={isOpen} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent mx={8}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Save Video Dialogue
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to save this video dialogue?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                                colorScheme="blue"
                                onClick={(e) => {
                                    handleSaveVideo();
                                }}
                                ml={3}
                            >
                                Save
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <HStack justifyContent={"center"} mb={4}>
                {feelingData &&
                    feelingData.length > 0 &&
                    feelingData.map((feeling, idx) => (
                        <Text key={idx} fontSize={16} fontWeight={600}>
                            {feeling.text}
                        </Text>
                    ))}
            </HStack>
            {content ? (
                <video src={content} style={size} controls />
            ) : (
                <VStack>
                    <Box>
                        {(() => {
                            switch (status) {
                                case "acquiring":
                                    return (
                                        <span className="fa-stack fa-lg">
                                            <i className="fa fa-video fa-stack-1x" />
                                        </span>
                                    );
                                case "previewing":
                                case "recording":
                                case "paused":
                                    return (
                                        <StreamVideo
                                            srcObject={liveVideo.stream}
                                            style={size}
                                            muted
                                        />
                                    );
                                case "denied":
                                    return (
                                        <span className="fa-stack fa-lg">
                                            <i className="fa fa-video fa-stack-1x" />
                                            <i className="fa fa-ban fa-stack-2x" />
                                        </span>
                                    );
                                case "recorded":
                                    return (
                                        <BlobVideo
                                            srcObject={capturedVideo.blob}
                                            style={size}
                                            controls
                                        />
                                    );
                                default:
                            }
                        })()}
                    </Box>
                    <VStack className="controls" mt={4}>
                        {(() => {
                            if (duration !== undefined) {
                                const seconds = duration / 1000;
                                const hh = Math.floor(seconds / 3600)
                                    .toString()
                                    .padStart(2, "0");
                                const mm = Math.floor((seconds / 60) % 60)
                                    .toString()
                                    .padStart(2, "0");
                                const ss = Math.floor(seconds % 60)
                                    .toString()
                                    .padStart(2, "0");

                                return (
                                    <HStack justifyContent={"center"}>
                                        <Box className="duration">{`${hh}:${mm}:${ss}`}</Box>
                                        <Box className="duration">{`${"00"}:${(
                                            limitTime / 60
                                        )
                                            .toString()
                                            .padStart(2, "0")}:${"00"}`}</Box>
                                    </HStack>
                                );
                            } else {
                                if (!devices || devices.length <= 1) {
                                    return <Box />;
                                } else {
                                    return (
                                        <Select
                                            onChange={(evt) =>
                                                selectDevice(evt.target.value)
                                            }
                                            value={selectedDeviceId}
                                        >
                                            {devices.map(({ label, id }) => {
                                                label = label.replace(
                                                    /\([0-9a-f]{4}:[0-9a-f]{4}\)/,
                                                    ""
                                                );
                                                return (
                                                    <option value={id} key={id}>
                                                        {label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    );
                                }
                            }
                        })()}
                        {(() => {
                            switch (status) {
                                case "acquiring":
                                case "denied":
                                case "previewing":
                                    return (
                                        <HStack
                                            className="buttons"
                                            justifyContent={"center"}
                                        >
                                            <Button
                                                colorScheme="blue"
                                                onClick={onStart}
                                                disabled={
                                                    status !== "previewing"
                                                }
                                            >
                                                Start
                                            </Button>
                                        </HStack>
                                    );
                                case "recording":
                                    return (
                                        <HStack
                                            className="buttons"
                                            justifyContent={"center"}
                                        >
                                            <Button
                                                colorScheme="blue"
                                                onClick={onPause}
                                            >
                                                Pause
                                            </Button>
                                            <Button
                                                colorScheme="blue"
                                                onClick={onStop}
                                            >
                                                Stop
                                            </Button>
                                        </HStack>
                                    );
                                case "paused":
                                    return (
                                        <HStack
                                            className="buttons"
                                            justifyContent={"center"}
                                        >
                                            <Button
                                                colorScheme="blue"
                                                onClick={onResume}
                                            >
                                                Resume
                                            </Button>
                                            <Button
                                                colorScheme="blue"
                                                onClick={onStop}
                                            >
                                                Stop
                                            </Button>
                                        </HStack>
                                    );
                                case "recorded":
                                    return (
                                        <HStack
                                            className="buttons"
                                            justifyContent={"center"}
                                        >
                                            <Button
                                                colorScheme="blue"
                                                onClick={onRetake}
                                            >
                                                Rerecord
                                            </Button>
                                            <Button
                                                colorScheme="blue"
                                                onClick={onAccept}
                                                disabled={status !== "recorded"}
                                            >
                                                Save
                                            </Button>
                                        </HStack>
                                    );
                                default:
                            }
                        })()}
                    </VStack>
                </VStack>
            )}
        </Box>
    );
};

export default VideoDialogBox;
