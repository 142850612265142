import { createContext, useState } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";

import { API_URLS } from "../Constants";
export const QuestionContext = createContext();

const QuestionProvider = ({ children }) => {
    const [cookies] = useCookies();
    const [date, setDate] = useState("");
    const [writingTime, setWritingTime] = useState("");
    const [sharingTime, setSharingTime] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [currentCategory, setCurrentCategory] = useState("-1");
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [currentQuestionID, setCurrentQuestionID] = useState("");
    const [curAppointment, setCurAppointment] = useState([]);
    
    const onNext = async (step) => {
        
        if (step === 1) {
            if (date === "" || writingTime === "" || sharingTime === "") {
                return {
                    result: false,
                    msg: "You have to select date and time for appointment.",
                };
            }
        } else if (step === 2) {
            if (currentCategory === '-1') 
                return {
                    result: false,
                    msg: "You haven't selected a category."
                }
            try {
                const response = await axios.get(API_URLS.DIALOGUE_QUESTIONS_LIST, {
                    params: { categoryId: currentCategory }
                });
                if (response.data.success) {
                    if (response.data.data[0]['question'] === '') {
                        if(cookies['dd_user_level'] === 0 || cookies['dd_user_level'] === 1 || cookies['dd_user_level'] === 2) {
                            return {
                                result: false,
                                msg: "You have to upgrade your account.",
                            };
                        } else {
                            setCurrentQuestion("");
                        }
                    } else {
                        setCurrentQuestions(response.data.data);
                        setCurrentQuestion(response.data.data[0]['question'])
                        setCurrentQuestionID(response.data.data[0]['_id'])
                    }
                    
                } else {
                    return {
                        result: false,
                        msg: response.data.message
                    }
                }
            } catch (e) {
                return {
                    result: false,
                    msg: e.msg
                }
            }

        } else if (step === 3) {
            if(currentQuestion.length === 0) {
                return {
                    result: false,
                    msg: "The question field is empty.",
                };
            }
        }

        return {
            result: true,
            msg: "Success",
        };
    };

    const onPrev = (step) => {
        if ( step === 1 ) {
        } else if (step === 2) {
        } else if (step === 3) {            
        }

        return {
            result: true,
            msg: "Success",
        };
    };

    return (
        <QuestionContext.Provider
            value={{
                date,
                setDate,
                writingTime,
                setWritingTime,
                sharingTime,
                setSharingTime,
                onNext,
                onPrev,
                categoryList,
                setCategoryList,
                currentQuestion,
                setCurrentQuestion,
                currentCategory,
                setCurrentCategory,
                curAppointment,
                setCurAppointment,
                currentQuestionID,
                setCurrentQuestionID,
                currentQuestions,
                setCurrentQuestions,
            }}
        >
            {children}
        </QuestionContext.Provider>
    );
};

export default QuestionProvider;
