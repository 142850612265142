import { Card, CardBody, CardHeader, VStack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../ckeditor-styles.css'
function Glossary(props) {
    const [glossary, setGlossary] = useState({});
    const toast = useToast();
    useEffect(()=> {
        const loadGlossary = async ()=> {
            try {
				const response = await axios.get(API_URLS.MATERIAL_GLOSSARY);
				if (response.data.success) {
					setGlossary(response.data.data);
				} else {
					toast({
						title: "Glossary",
						description: `${response.data.message}.`,
						bg: 'blue',
                color: 'white',
						duration: 3000,
						isClosable: true,
					});
				}
			} catch (e) {
				console.error(e);
			}    
        }
        loadGlossary();

        
    }, [toast]);
    return (
        <Card  minH={"600px"} w={'full'}>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <VStack align={'start'}>
                    <Text>Abbreviations</Text>
                    <Card w={'full'}>
                        <CardBody>
                            {glossary[0] && glossary[0].abbreviations && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={glossary[0]['abbreviations']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font, 
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                          ],
                                    }}
                                    disabled
                                />
                            )}
                        </CardBody>
                    </Card>
                    <Text>Terms</Text>
                    <Card w={'full'}>
                        <CardBody>
                            {glossary[0] && glossary[0].terms && (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={glossary[0]['terms']}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font, 
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                          ],
                                    }}
                                    disabled
                                />
                            )}</CardBody>
                    </Card>
                </VStack>
            </CardBody>
        </Card>
    )
}

export default Glossary;