import { Box, VStack, Image, Button, HStack } from "@chakra-ui/react";
import splash from "../assets/imgs/splash.png";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../providers/AuthProvider";
import { API_URLS } from "../Constants";
import NextAppointment from "../components/NextAppointmentItem";
import {
    Heading,
    useColorModeValue,
    useToast,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Text,
} from "@chakra-ui/react";
import { AppContext } from "../providers/AppProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import moment from "moment";
import { FaUser } from "react-icons/fa";
import { useWebSocket } from "../providers/SocketProvider";
import { useCookies } from "react-cookie";

function Home() {
    const { cookieAlive } = useContext(AuthContext);
    const { setMakeTimeEllapsed, setShareTimeEllapsed } =
        useContext(DialogueContext);
    const themeColor = useColorModeValue("blue.400", "blue.300");
    const toast = useToast();
    const [appointments, setAppointments] = useState([]);
    const { contactInfo } = useContext(AppContext);
    const navigate = useNavigate();

    const handleNewDialogue = () => {
        createAppointment();
    };

    useEffect(() => {
        if (cookieAlive()) {
            if (Object.keys(contactInfo).length === 0) {
                navigate("/settings");
                return;
            }
            getAppointments();
        }
    }, [cookieAlive, navigate, contactInfo]);

    const getAppointments = async () => {
        try {
            let formattedDate = moment().format("YYYY-MM-DD");

            const response = await axios.post(API_URLS.DIALOGUE_APPTS, {
                email: cookieAlive(),
                date: formattedDate,
            });
            if (response.data.success) {
                setAppointments(response.data.data);
            } else {
                toast({
                    title: "Upcoming dialogues",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const createAppointment = async () => {
        try {
            const response = await axios.get(API_URLS.DIALOGUE_CHECK_COUNT, {
                params: {
                    email: cookieAlive(),
                },
            });

            if (response.data.success) {
                if (response.data.shouldDisable) {
                    toast({
                        title: "User Subscription",
                        description: response.data.message,
                        duration: 3000,
                        isClosable: true,
                        bg: "blue",
                        color: "white",
                    });
                } else {
                    navigate("/create");
                }
            } else {
                toast({
                    title: "User Category",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onItemEvent = (type, id, extra, date) => {
        switch (type) {
            case "remove":
                getAppointments();
                break;
            case "share":
                setShareTimeEllapsed(0);
                navigate("/share", { state: { dataId: id, type: extra, apptId: date } });
                // navigate('/share');
                break;
        }
    };

    return (
        <VStack p={4} w={"auto"} h={"full"}>
            <HStack w={"auto"} justifyContent={"center"} h={"full"}>
                <Image
                    display={{
                        base: "none",
                        sm: "none",
                        md: "none",
                        xl: "block",
                    }}
                    src={splash}
                    maxW={"300px"}
                    h={"auto"}
                    onClick={handleNewDialogue}
                    cursor={"pointer"}
                />
                <Box h={"full"} w={"full"}>
                    {!cookieAlive() && (
                        <VStack
                            spacing={4}
                            w={"full"}
                            h={"full"}
                            minWidth={{
                                base: 0,
                                md: "300px",
                                sm: "200px",
                                xl: "480px",
                            }}
                        >
                            <Button
                                colorScheme="blue"
                                size={"lg"}
                                _hover={{
                                    bg: "#415b73",
                                    color: "white",
                                }}
                                leftIcon={<FaUser />}
                                onClick={() => {
                                    navigate("/login");
                                }}
                            >
                                Login
                            </Button>
                            <Image
                                display={{
                                    base: "block",
                                    sm: "block",
                                    md: "block",
                                    xl: "none",
                                }}
                                src={splash}
                                maxW={"300px"}
                                h={"auto"}
                                onClick={handleNewDialogue}
                                cursor={"pointer"}
                            />
                        </VStack>
                    )}
                    {cookieAlive() &&
                        appointments &&
                        appointments.length === 0 && (
                            <VStack spacing={4} w={"full"} h={"full"}>
                                <Button
                                    colorScheme="blue"
                                    _hover={{
                                        bg: "#415b73",
                                        color: "white",
                                    }}
                                    onClick={createAppointment}
                                >
                                    Dialogue Now!
                                </Button>
                                <Heading textAlign="center">
                                    You don't have any upcoming dialogues
                                </Heading>
                                <Image
                                    display={{
                                        base: "block",
                                        sm: "block",
                                        md: "block",
                                        xl: "none",
                                    }}
                                    src={splash}
                                    maxW={"300px"}
                                    h={"auto"}
                                    onClick={handleNewDialogue}
                                    cursor={"pointer"}
                                />
                            </VStack>
                        )}
                    {cookieAlive() &&
                        appointments &&
                        appointments.length > 0 && (
                            <VStack spacing={4} w={"full"} maxW={"665px"}>
                                <Button
                                    onClick={() => navigate("/create")}
                                    colorScheme="blue"
                                    _hover={{
                                        bg: "#415b73",
                                        color: "white",
                                    }}
                                >
                                    Dialogue Now!
                                </Button>
                                <Accordion
                                    defaultIndex={[0]}
                                    w={"auto"}
                                    allowToggle
                                    maxH={"600px"}
                                    overflowY={"scroll"}
                                    css={{
                                        "&::-webkit-scrollbar": {
                                            width: "4px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#e2e8f0",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            backgroundColor: "#e2e8f0",
                                        },
                                    }}
                                >
                                    {appointments?.map((appointment, idx) => (
                                        <AccordionItem
                                            key={idx}
                                            border={"none"}
                                            mb={"2px"}
                                        >
                                            <AccordionButton
                                                bgColor={themeColor}
                                                as={Button}
                                                color={"white"}
                                                _hover={{
                                                    bg: "#415b73",
                                                    color: "white",
                                                }}
                                                minW={{
                                                    base: "inherit",
                                                    sm: "inherit",
                                                    md: "665px",
                                                    xl: "665px",
                                                }}
                                                w={{ base: "full", sm: "full" }}
                                            >
                                                <Text>{appointment.date}</Text>
                                            </AccordionButton>
                                            <AccordionPanel p={0}>
                                                <NextAppointment
                                                    data={appointment}
                                                    onItemEvent={onItemEvent}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                                <Image
                                    display={{
                                        base: "block",
                                        sm: "block",
                                        md: "block",
                                        xl: "none",
                                    }}
                                    src={splash}
                                    maxW={"300px"}
                                    h={"auto"}
                                    onClick={handleNewDialogue}
                                    cursor={"pointer"}
                                />
                            </VStack>
                        )}
                </Box>
            </HStack>
        </VStack>
    );
}

export default Home;
