import {
    VStack,
    HStack,
    Button,
    Spacer,
    Heading,
    Text,
} from "@chakra-ui/react";
import DDStepper from "./DDStepper";
const DDHeader = ({title='', activeStep = 1, steps = [], question='', handleBack}) => {
    return (
        <VStack w={'full'} gap={4}>
            <HStack w={'full'} justifyContent={'space-between'}>
                <Heading size={'md'} fontWeight={600}>{title}</Heading>
                <Spacer />
                <Button w={100} onClick={handleBack} size={'sm'} _hover={{ bg: '#3182ce', color: 'white' }}>
                    Back
                </Button>
            </HStack>
            {steps &&
                <DDStepper activeStep={activeStep} steps={steps} />
            }
            <Text fontWeight={700}>{question}</Text>
        </VStack>
    )
}

export default DDHeader;