import '../ckeditor-styles-review.css'
import {
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Heading,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API_URLS } from "../Constants";
import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import DialogueEmotion from "../components/dialogue/dialogue-emotion";
import DialogueCategory from "../components/dialogue/dialogue-category";
import DialogueLetter from "../components/dialogue/dialogue-letter";
import DDHeader from "../components/common/DDHeader";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';

function WriteDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        onNext,
        onPrev,
        emotion,
        feelingData,
        content,
        addFlag,
        setEmotion,
        setFeelingData,
        setContent,
        setAddFlag,
    } = useContext(DialogueContext);
    const { cookieAlive } = useContext(AuthContext);
    const [cookies] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const steps = [
        {
            title: "Emotion",
            description: "Choose Emotion Category",
        },
        {
            title: "Feelings",
            description: "Choose Feelings (Max 3)",
        },
        {
            title: "Letter",
            description: "Write Dialogue Letter",
        },
    ];
    const dialogueLetterRef = useRef(null);

    const stopDialogueLetterTimer = () => {
        if (dialogueLetterRef.current) {
            dialogueLetterRef.current.stopTimer();
        }
    };

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    useEffect(() => {
        const getCheckExistLetter = async () => {
            setEmotion("");
            setFeelingData([]);
            setContent("");

            if (location.state.apptId) {
                const response = await axios.post(
                    API_URLS.DIALOGUE_GET_SEL_APPT_LETTER,
                    {
                        appointId: location.state.apptId,
                        userEmail: cookies["dd_user_email"],
                    }
                );

                if (response.data.draft) {
                    setAddFlag(true);
                    setEmotion(response.data.draft.emotion);
                    setFeelingData(response.data.draft.feelingData);
                    setContent(response.data.draft.content);
                    setActiveStep(2);
                } else {
                    if (response.data.dialogue && response.data.dialogue._id) {
                        setAddFlag(false);
                        setEmotion(response.data.dialogue.emotion);
                        setFeelingData(response.data.dialogue.feelings);
                        setContent(response.data.dialogue.letter);
                    } else {
                        setAddFlag(true);
                    }
                }
            }
        };
        getCheckExistLetter();
    }, [
        cookies,
        setAddFlag,
        setEmotion,
        setFeelingData,
        setContent,
    ]);

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!location.state.apptId) {
            navigate("/");
        }
    }, [cookieAlive, navigate]);

    const handlePrev = () => {
        let step = activeStep;
        const prevResult = onPrev(step);
        if (!prevResult.result) {
            toast({
                title: "Write Dialogue",
                description: `${prevResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: "blue",
                color: "white",
            });
            return;
        }
        if (activeStep - 1 < 1) step = 1;
        else step = activeStep - 1;
        setActiveStep(step);
    };

    const handleNext = () => {
        let step = activeStep;
        
        const nextResult = onNext(step);
        if (!nextResult.result) {
            toast({
                title: "Write Dialogue",
                description: `${nextResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: "blue",
                color: "white",
            });
            return;
        }

        if (activeStep + 1 > 3) onOpen();
        else step = activeStep + 1;

        setActiveStep(step);
    };

    const handleSaveDraft = async () => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_SAVE_DRAFT, {
                emotion: emotion,
                feelingData: feelingData,
                content: content,
                appointmentId: location.state.apptId,
                userEmail: cookies["dd_user_email"],
            });
            if (response.data.success) {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: "blue",
                    color: "white",
                });
                navigate("/");
            } else {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: "blue",
                    color: "white",
                });
            }
        } catch (e) {
            toast({
                title: "Write Dialogue",
                description: `${e.message}.`,
                duration: 3000,
                isClosable: true,
                bg: "blue",
                color: "white",
            });
        }
    };

    const handleSaveDialogue = async () => {
        stopDialogueLetterTimer();

        try {
            const response = await axios.post(API_URLS.DIALOGUE_WRITE, {
                emotion: emotion,
                feelings: feelingData,
                letter: content,
                appointmentId: location.state.apptId,
                appointmentDate: location.state.apptDate,
                userId: cookies["dd_user_id"],
                userEmail: cookies["dd_user_email"],
                spouseEmail: cookies["dd_spouse_email"],
            });

            if (response.data.success) {
                onClose();
                navigate("/");
            } else {
                toast({
                    title: "Write Dialogue",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Write Dialogue",
                description: `${e.message}.`,
                bg: "blue",
                color: "white",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleBack = () => {
        navigate("/");
    };

    return (
        <VStack w={isLargerThan830 ? "auto": "full"} h={"100%"}>
            {addFlag ? (
                <>
                    <VStack h={'100%'} w={isLargerThan830 ? "auto": "full"}>
                        <Card
                            w={
                                isLargerThan830
                                    ? "auto"
                                    : "-webkit-fill-available"
                            }
                            minH={"400px"}
                            h={"100%"}
                            minW={isLargerThan830 ? "400px" : "350px"}
                            p={4}
                        >
                            <CardHeader>
                                <DDHeader
                                    title={"Writing"}
                                    activeStep={activeStep}
                                    steps={steps}
                                    question={location.state.question}
                                    handleBack={handleBack}
                                />
                            </CardHeader>
                            <CardBody>
                                {activeStep === 1 && (
                                    <DialogueEmotion
                                        handleEmotionSelect={handleNext}
                                    />
                                )}
                                {activeStep === 2 && <DialogueCategory />}
                                {activeStep === 3 && (
                                    <DialogueLetter ref={dialogueLetterRef} onTime={onOpen} />
                                )}
                            </CardBody>
                            <CardFooter>
                                {activeStep !== 1 && (
                                    <HStack w={"full"}>
                                        <Button
                                            onClick={handlePrev}
                                            disabled={activeStep === 1}
                                            _hover={{
                                                bg: "#3182ce",
                                                color: "white",
                                            }}
                                        >
                                            Previous
                                        </Button>
                                        <Spacer />
                                        <Button
                                            colorScheme="blue"
                                            onClick={handleSaveDraft}
                                            display={activeStep !== 1}
                                        >
                                            Save Draft
                                        </Button>
                                        <Button
                                            onClick={handleNext}
                                            disabled={activeStep === 3}
                                            _hover={{
                                                bg: "#3182ce",
                                                color: "white",
                                            }}
                                        >
                                            {activeStep === 1
                                                ? "Next"
                                                : activeStep === 2
                                                    ? "Next"
                                                    : "Finish"}
                                        </Button>
                                    </HStack>
                                )}
                            </CardFooter>
                        </Card>
                    </VStack>
                    <AlertDialog isOpen={isOpen} onClose={onClose}>
                        <AlertDialogOverlay>
                            <AlertDialogContent mx={8}>
                                <AlertDialogHeader
                                    fontSize="lg"
                                    fontWeight="bold"
                                >
                                    Save Dialogue
                                </AlertDialogHeader>

                                <AlertDialogBody>Are you sure?</AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button
                                        colorScheme="teal"
                                        onClick={handleSaveDialogue}
                                        ml={3}
                                    >
                                        Save
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </>
            ) : (
                <Card
                    w={'auto'}
                    // maxW={"665px"}
                    minH={"400px"}
                    h={"100%"}
                    minW={isLargerThan830 ? "400px" : "350px"}
                    p={4}
                >
                    <CardHeader>
                        <DDHeader
                            title={"Review"}
                            steps={false}
                            handleBack={handleBack}
                        />
                    </CardHeader>
                    <CardBody w={"full"}>
                        <VStack w={'full'} gap={2} alignItems={'baseline'}>
                            <Heading fontSize="xl">Question</Heading>
                            <Text fontWeight={600}>{location.state.question}</Text>
                            <Heading fontSize="xl">Emotion</Heading>
                            <Text>
                                {emotion === "Yes" ? "Pleasant" : "Unpleasant"}
                            </Text>
                            <Heading fontSize="xl">Feeling Data</Heading>
                            <Text>
                                {feelingData
                                    .map((feeling) => feeling.text)
                                    .join(", ")}
                            </Text>
                            <Heading fontSize="xl">Letter</Heading>
                            {/* <Text>{content}</Text> */}
                            <CKEditor
                                w={"full"}
                                editor={ClassicEditor}
                                config={
                                    {
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }

                                }
                                data={content ? content : ''}
                                disabled
                            />
                        </VStack>
                    </CardBody>
                </Card>
            )}
        </VStack>
    );
}

export default WriteDialogue;
