import axios from "axios";
import { useCookies } from 'react-cookie';
import { useContext, useEffect, useState } from "react";
import {
    Text, VStack, HStack, Box,
    Heading,
} from "@chakra-ui/react";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { API_URLS } from "../../Constants";
import '../../ckeditor-styles.css'
const HistoryItem = ({ data, role }) => {
    const [curData, setCurData] = useState({});
    const [curVideoData, setCurVideoData] = useState({});
    const [curAudioData, setCurAudioData] = useState({});
    const [curLetterFlag, setCurLetterFlag] = useState(false);
    const [curVideoFlag, setCurVideoFlag] = useState(false);
    const [curAudioFlag, setCurAudioFlag] = useState(false);
    const [cookies] = useCookies();

    useEffect(() => {
        let userEmail = ''
        if (role == 'husband') {
            userEmail = cookies['dd_h_email'];
        } else {
            userEmail = cookies['dd_w_email'];
        }

        const checkShareLetter = async () => {
            const response = await axios.post(API_URLS.DIALOGUE_GET_SHARE_APPT_LETTER, {
                appointId: data._id,
                userEmail
            });
            if (response.data._id) {
                setCurData(response.data);
                setCurLetterFlag(true);
            } else {
                setCurData({});
                setCurLetterFlag(false);
            }
        };

        const checkShareVideo = async () => {
            const response = await axios.post(API_URLS.DIALOGUE_GET_SHARE_APPT_VIDEO, {
                appointId: data._id,
                userEmail
            });
            if (response.data._id) {
                setCurVideoData(response.data);
                setCurVideoFlag(true);
            } else {
                setCurVideoData({});
                setCurVideoFlag(false);
            }
        };

        const checkShareAudio = async () => {
            const response = await axios.post(API_URLS.DIALOGUE_GET_SHARE_APPT_AUDIO, {
                appointId: data._id,
                userEmail
            });
            if (response.data._id) {
                setCurAudioData(response.data);
                setCurAudioFlag(true);

            } else {
                setCurAudioData({});
                setCurAudioFlag(false);
            }
        };

        checkShareLetter();
        checkShareVideo();
        checkShareAudio();
    }, [data]);
    return (
        <Box>
            <VStack w={'full'} justifyContent={'start'}>
                <Text fontSize={16} w={'full'}>{data.question}</Text>
                <HStack w={'full'}>
                    <Heading fontSize='md'>Emotion: </Heading>
                    {(() => {
                        if (curData) {
                            if (curLetterFlag) {
                                const emotion = curData.emotion === 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            } else if (curVideoFlag) {
                                const emotion = curVideoData.emotion === 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            } else if (curAudioFlag) {
                                const emotion = curAudioData.emotion === 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            }
                        }
                    })()}
                </HStack>
                {(() => {
                    if (curLetterFlag && curData) {
                        return (
                            <>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curData && curData.feelings && curData.feelings.length > 0 && curData.feelings.map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        plugins: [
                                            Essentials, Bold, Italic, Paragraph, Font,
                                            Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                            List, Indent, IndentBlock, Alignment, Link, AutoLink
                                        ],
                                    }
                                    }
                                    data={curData ? curData.letter : '<p></p>'}
                                    disabled
                                />
                            </>

                        )
                    }
                })()}
                {(() => {
                    if (curVideoFlag) {
                        return (
                            <>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curVideoData && curVideoData.feelings && JSON.parse(curVideoData.feelings).length > 0 && JSON.parse(curVideoData.feelings).map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <video src={`${process.env.REACT_APP_UPLOAD_URL}${curVideoData.videoPath}`} controls />
                            </>

                        )
                    }
                })()}
                {(() => {
                    if (curAudioFlag) {
                        return (
                            <>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curAudioData && curAudioData.feelings && JSON.parse(curAudioData.feelings).length > 0 && JSON.parse(curAudioData.feelings).map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <audio src={`${process.env.REACT_APP_UPLOAD_URL}${curAudioData.audioPath}`} controls />
                            </>

                        )
                    }
                })()}
            </VStack>



        </Box>
    )
}

export default HistoryItem;