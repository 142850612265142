import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    VStack,
    Input,
    Card,
    Container,
    InputGroup,
    InputRightElement,
    useColorModeValue,
    Text,
    Button,
    Link as ChakraLink,
    HStack,
    useToast,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { useContext, useState, useEffect, useCallback } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { AuthContext } from "../providers/AuthProvider";
import { AppContext } from "../providers/AppProvider";
import axios from "axios";
import { API_URLS } from "../Constants";
import { useCookies } from "react-cookie";

function Login() {
    const themeColor = useColorModeValue("blue.400", "blue.300");
    const toast = useToast();
    const navigate = useNavigate();
    const { saveUserInfoCookie } = useContext(AuthContext);
    const { contactInfo, setContactInfo, Init } = useContext(AppContext);
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [cookies, setCookie] = useCookies();

    const emailRegex = new RegExp(
        /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
        "gm"
    );

    const [showPwd, setShowPwd] = useState(false);
    const handleShowPassword = () => setShowPwd(!showPwd);

    const { cookieAlive } = useContext(AuthContext);

    useEffect(() => {
        if (cookieAlive()) {
            if (Object.keys(contactInfo).length === 0) {
                navigate("/settings");
            }
        }
    }, [cookieAlive, navigate, contactInfo]);

    const handleChangeEmail = (e) => {
        if (e.target.value && emailRegex.test(e.target.value)) {
            setEmailValid(true);
            setEmail(e.target.value);
        } else {
            setEmailValid(false);
        }
    };

    const handleChangePwd = (e) => {
        setPwd(e.target.value);
    };

    const handleLogin = useCallback(async () => {
        if (pwd.length === 0 || !emailValid) {
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(API_URLS.LOGIN, {
                email,
                password: pwd,
            });

            if (response.data.success && response.data.user) {
                if (Object.keys(response.data.user.contact).length > 0) {
                    let spouseEmail = "";
                    if (
                        response.data.user.contact.hEmail.toLowerCase() ===
                        email.toLowerCase()
                    ) {
                        spouseEmail = response.data.user.contact.wEmail;
                        setCookie(
                            "dd_user_email",
                            response.data.user.contact.hEmail
                        );
                    } else if (
                        response.data.user.contact.wEmail.toLowerCase() ===
                        email.toLowerCase()
                    ) {
                        spouseEmail = response.data.user.contact.hEmail;
                        setCookie(
                            "dd_user_email",
                            response.data.user.contact.wEmail
                        );
                    }

                    setCookie("dd_h_email", response.data.user.contact.hEmail);
                    setCookie("dd_w_email", response.data.user.contact.wEmail);

                    saveUserInfoCookie({
                        id: response.data.user._id,
                        email,
                        spouseEmail,
                        token: response.data.token,
                        level: response.data.user.subscription?.plan
                            ? response.data.user.subscription?.plan
                            : 0,
                        // words_appointment: response.data.user.words?.appointment
                        //     ? response.data.user.words?.appointment
                        //     : "",
                        // words_done: response.data.user.words?.done
                        //     ? response.data.user.words?.done
                        //     : "",
                        // words_share: response.data.user.words?.share
                        //     ? response.data.user.words?.share
                        //     : "",
                    });

                    Init();
                    setContactInfo(response.data.user.contact);

                    toast({
                        title: "Login Success",
                        description: `${response.data.message}.`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    navigate("/");
                } else {
                    setCookie(
                        "dd_user_email",
                        email
                    );
                    Init();
                    toast({
                        title: "More information",
                        description: `Please complete ALL the settings.`,
                        status: "info",
                        duration: 10000,
                        isClosable: true,
                    });
                    navigate("/settings");
                }
            } else {
                toast({
                    title: "Login Error",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    });

    return (
        <Box className="App-login" alignItems={"center"} mt={8} w={"full"}>
            <Container w={"full"}>
                <Card w={"full"} maxW={420} p={4} m={"auto"}>
                    <VStack spacing={4} w={"full"}>
                        <Text color={themeColor} fontSize={24} fontWeight={700}>
                            Login
                        </Text>
                        <FormControl isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="Email"
                                placeholder="Enter email"
                                defaultValue={email}
                                onChange={handleChangeEmail}
                                {...(emailValid
                                    ? { borderColor: "green.500" }
                                    : { borderColor: "red.500" })}
                            />
                            {!emailValid ? (
                                <FormHelperText color="red.500">
                                    Input valid email address
                                </FormHelperText>
                            ) : (
                                <></>
                            )}
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Password</FormLabel>
                            <InputGroup size="md">
                                <Input
                                    pr="4.5rem"
                                    type={showPwd ? "text" : "password"}
                                    placeholder="Enter password"
                                    defaultValue={pwd}
                                    onChange={handleChangePwd}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button
                                        h="1.75rem"
                                        size="sm"
                                        onClick={handleShowPassword}
                                    >
                                        {showPwd ? (
                                            <AiFillEye />
                                        ) : (
                                            <AiFillEyeInvisible />
                                        )}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <FormHelperText>
                                <ChakraLink
                                    as={ReactRouterLink}
                                    to="/forgotpwd"
                                    _hover={{ color: themeColor }}
                                >
                                    <Text>Forgot Password</Text>
                                </ChakraLink>
                            </FormHelperText>
                        </FormControl>

                        <Button
                            colorScheme="blue"
                            width="100%"
                            onClick={(e) => {
                                handleLogin();
                            }}
                            isLoading={isLoading}
                        >
                            Login
                        </Button>
                        <HStack>
                            <Text>Don't have an account?</Text>
                            <ChakraLink
                                as={ReactRouterLink}
                                to="/register"
                                _hover={{ color: themeColor }}
                            >
                                <Text>Register</Text>
                            </ChakraLink>
                        </HStack>
                    </VStack>
                </Card>
            </Container>
        </Box>
    );
}

export default Login;
