import {
    Box,
    HStack,
    Image,
    Link as ChakraLink,
    Spacer,
    Text,
    Button,
    Menu,
    MenuItem,
    MenuButton,
    MenuDivider,
    MenuList,
    IconButton,
    VStack,
} from "@chakra-ui/react";
import { FaUser, FaBars, FaCog } from "react-icons/fa";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import ddLogo from "../assets/imgs/logo.png";
import ThemeSwitcher from "../components/ThemeSwitcher";
import { useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";

function Header() {
    const location = useLocation();
    const logoColor = useColorModeValue("blue.400", "gray.50");
    const userIcon = useColorModeValue(<FaUser />, <FaUser />);
    const themeColor = useColorModeValue("blue.400", "blue.300");
    const { logout, cookieAlive } = useContext(AuthContext);

    const navigate = useNavigate();

    const userName = () => {
        if (cookieAlive()) {
            return cookieAlive();
        } else {
            return "";
        }
    };

    const handleGotoHomepage = () => {
        navigate("/");
    };

    return (
        <Box className="App-header" py={8} px={7} w={"full"}>
            <HStack>
                <HStack
                    onClick={(e) => {
                        handleGotoHomepage();
                    }}
                    cursor={"pointer"}
                >
                    <Image src={ddLogo} w={"48px"} />
                    <ChakraLink
                        as={ReactRouterLink}
                        to="/"
                        color={logoColor}
                        _hover={{ color: themeColor }}
                    >
                        <Text fontSize={24} fontWeight={800}>
                            Dialogue Daily
                        </Text>
                    </ChakraLink>
                </HStack>
                <Spacer />
                <HStack spacing={{ xl: 8, base: 4, md: 4 }}>
                    {cookieAlive() && (
                        <ChakraLink
                            as={ReactRouterLink}
                            to="/"
                            _hover={{ color: themeColor }}
                            display={{ xl: "flex", base: "none", md: "flex" }}
                            {...(location.pathname === "/" && {
                                color: themeColor,
                            })}
                        >
                            <Text fontSize={16} fontWeight={600}>
                                Home
                            </Text>
                        </ChakraLink>
                    )}
                    {cookieAlive() && (
                        <ChakraLink
                            as={ReactRouterLink}
                            to="/history"
                            _hover={{ color: themeColor }}
                            display={{ xl: "flex", base: "none", md: "flex" }}
                            {...(location.pathname === "/history" && {
                                color: themeColor,
                            })}
                        >
                            <Text fontSize={16} fontWeight={600}>
                                History
                            </Text>
                        </ChakraLink>
                    )}

                    {cookieAlive() && (
                        <ChakraLink
                            as={ReactRouterLink}
                            to="https://dialoguedaily.com/resources"
                            isExternal
                            _hover={{ color: themeColor }}
                            display={{ xl: "flex", base: "none", md: "flex" }}
                        >
                            <Text fontSize={16} fontWeight={600}>
                                Resources
                            </Text>
                        </ChakraLink>
                    )}

                    {cookieAlive() && (
                        <ChakraLink
                            as={ReactRouterLink}
                            isExternal
                            to="https://dialoguedaily.com"
                            _hover={{ color: themeColor }}
                            display={{ xl: "flex", base: "none", md: "flex" }}
                        >
                            <Text fontSize={16} fontWeight={600}>
                                About Us
                            </Text>
                        </ChakraLink>
                    )}
                    <Spacer />
                    {cookieAlive() && (
                        <Menu>
                            <MenuButton
                                as={Button}
                                leftIcon={<FaBars />}
                                display={{
                                    base: "flex",
                                    md: "none",
                                    xl: "none",
                                    sm: "flex",
                                }}
                            />
                            <MenuList>
                                <MenuItem onClick={() => navigate("/")}>
                                    Home
                                </MenuItem>
                                <MenuItem onClick={() => navigate("/history")}>
                                    History
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        window.open(
                                            "https://dialoguedaily.com/resources",
                                            "_blank"
                                        )
                                    }
                                >
                                    Resources
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        window.open(
                                            "https://dialoguedaily.com",
                                            "_blank"
                                        )
                                    }
                                >
                                    About Us
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={() => navigate("/settings")}>
                                    Settings
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        window.open(
                                            "https://dialoguedaily.com/pricing",
                                            "_blank"
                                        )
                                    }
                                >
                                    Upgrade
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={logout}>Log out</MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                    {cookieAlive() && (
                        <Button
                            leftIcon={userIcon}
                            size={"sm"}
                            colorScheme="blue"
                            onClick={(e) =>
                                window.open(
                                    "https://dialoguedaily.com/pricing",
                                    "_blank"
                                )
                            }
                            display={{ base: "none", md: "flex", xl: "flex" }}
                        >
                            Upgrade
                        </Button>
                    )}
                    {cookieAlive() && (
                        <HStack gap={2}>
                            <IconButton
                                icon={<FaCog />}
                                size={"sm"}
                                colorScheme="blue"
                                onClick={() => navigate("/settings")}
                                display={{
                                    base: "none",
                                    md: "none",
                                    xl: "flex",
                                }}
                            />
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    aria-label="Profile"
                                    icon={<FaBars />}
                                    size={"sm"}
                                    variant="ghost"
                                    display={{
                                        base: "none",
                                        md: "flex",
                                        xl: "flex",
                                    }}
                                >
                                    {userName()}
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => navigate("/")}>
                                        Home
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => navigate("/history")}
                                    >
                                        History
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            window.open(
                                                "https://dialoguedaily.com/resources",
                                                "_blank"
                                            )
                                        }
                                    >
                                        Resources
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            window.open(
                                                "https://dialoguedaily.com",
                                                "_blank"
                                            )
                                        }
                                    >
                                        About Us
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem
                                        onClick={() => navigate("/settings")}
                                    >
                                        Settings
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            window.open(
                                                "https://dialoguedaily.com/pricing",
                                                "_blank"
                                            )
                                        }
                                    >
                                        Upgrade
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem onClick={logout}>
                                        Log out
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    )}
                    {/* <ThemeSwitcher justifySelf="flex-end" /> */}
                </HStack>
            </HStack>
        </Box>
    );
}

export default Header;
