import { useContext, useEffect, useState } from "react";
import { QuestionContext } from "../../providers/QuestionProvider";
import { RadioGroup, Radio, VStack, Box, Textarea, Text } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../providers/AuthProvider";
import { API_URLS } from "../../Constants";
import axios from "axios";

function AppointmentQuestions() {
    const { currentQuestionID, currentQuestion, setCurrentQuestion, setCurrentQuestionID, currentQuestions, currentCategory, categoryList } = useContext(QuestionContext);
    const { cookieAlive } = useContext(AuthContext);
    const [cookies] = useCookies();
    const [filtered, setFiltered] = useState([]);

    const handleQuestion = (e) => {
        setCurrentQuestion(e.target.name);
        setCurrentQuestionID(e.target.value);
    }

    useEffect(() => {
        const getUsedQuestions = async () => {
            try {
                const response = await axios.post(API_URLS.DIALOGUE_ALL_APPTS, { email: cookies['dd_user_email'] });
                if (response.data.success) {
                    const filteredQuestions = currentQuestions.filter((question, idx) =>
                        !response.data.data.map(item => item.questionID).includes(question._id)
                        , response.data.data);
                    setFiltered(filteredQuestions);
                }
            } catch (e) {

            }
        }

        getUsedQuestions();
    }, [cookieAlive]);
    const handleCustomQuestion = (e) => {
        setCurrentQuestion(e.target.value);
    }

    return (
        <Box w={'auto'}>
            {currentCategory === '66ccbd8888c7e90f2d198dd9' ? (
                <VStack>
                    <Text fontSize={'3xl'} fontWeight={'500'}>Write your own question</Text>
                    <Textarea value={currentQuestion} onChange={handleCustomQuestion}>
                    </Textarea>
                </VStack>
            ) : (
                <RadioGroup value={currentQuestionID} defaultValue={currentQuestionID} w={'auto'} id="question-list">
                    <VStack align={'start'} w={'auto'} maxW={'665px'} maxH="500px" overflow="auto" css={{
                        '&::-webkit-scrollbar': { width: '4px' },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
                        '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' }
                    }}>
                        {filtered && filtered.map((question, idx) => (
                            <Radio value={question._id} name={`${question.question}`} key={idx} onChange={handleQuestion}>
                                {question.question}
                            </Radio>
                        ))}
                    </VStack>
                </RadioGroup >
            )}
        </Box >
    );
}

export default AppointmentQuestions;