import {
    Button,
    Card,
    CardBody,
    CardHeader,
    HStack,
    VStack,
    Text,
    CardFooter,
    useToast,
    useMediaQuery,
    Grid,
    Box,
    GridItem,
} from "@chakra-ui/react";
import axios from "axios";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { FaSave } from "react-icons/fa";
import { useContext, useState, useEffect } from "react";
import { API_URLS } from "../../Constants";
import moment from "moment";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { useCookies } from "react-cookie";
function Appointment() {
    const toast = useToast();
    const [isSaving, setIsSaving] = useState(false);
    const { cookieAlive } = useContext(AuthContext);
    const { appointment, setAppointment } = useContext(AppContext);
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
    const [cookies] = useCookies();
    const handleSave = async () => {
        try {
            setIsSaving(true);
            const spouseEmail = cookies["dd_spouse_email"];
            console.log(appointment);
            const response = await axios.post(API_URLS.APPT_UPDATE, {
                appointments: appointment,
                email: cookieAlive(),
                spouseEmail,
            });
            if (response.data.success) {
                toast({
                    title: "Appointment Defaults",
                    description: `${response.data.message}.`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Appointment Defaults",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleChangeWritingTime = (item, e) => {
        const v = dayjs(e).format("HH:mm");
        const newAppointment = { ...appointment };
        newAppointment[item] = v;
        setAppointment(newAppointment);
    };

    const handleChangeSharingTime = (item, e) => {
        const v = dayjs(e).format("HH:mm");
        const newAppointment = { ...appointment };
        newAppointment[item] = v;
        setAppointment(newAppointment);
    };

    useEffect(() => {
        const loadAppointment = async () => {
            if (!cookieAlive()) return;
            console.log('reset appointment')
            try {
                const response = await axios.post(API_URLS.APPT_INFO, {
                    email: cookieAlive(),
                });
                if (response.data.success) {
                    const appointment = response.data.data;
                    setAppointment(appointment);
                } else {
                    toast({
                        title: "Appointment Defaults",
                        description: `${response.data.message}.`,
                        bg: "blue",
                        color: "white",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadAppointment();
    }, []);

    return (
        <Card minH={"600px"} w={"full"}>
            <CardHeader>Appointment Defaults</CardHeader>
            <CardBody>
                <HStack>
                    <VStack alignItems={'center'}>
                        <Text>&nbsp;</Text>
                        {weekDays.map((weekDay, index) => (
                            <Text key={index} lineHeight={'35.14px'}>{weekDay}</Text>
                        ))}
                    </VStack>
                    <VStack
                        alignItems={'center'}
                    >
                        <Text>
                            Writing Time
                        </Text>
                        {Object.keys(appointment).map(
                            (item, idx) =>
                                idx % 2 === 0 && (
                                    <TimePicker
                                        key={idx}
                                        use12Hours
                                        format="h:mm a"
                                        value={dayjs(
                                            appointment[item],
                                            "HH:mm"
                                        )}
                                        onChange={(e) => {
                                            handleChangeWritingTime(
                                                item,
                                                e
                                            );
                                        }}
                                    />
                                )
                        )}
                    </VStack>
                    <VStack
                        alignItems={'center'}
                    >
                        <Text>Sharing Time</Text>
                        {Object.keys(appointment).map(
                            (item, idx) =>
                                idx % 2 === 1 && (
                                    <TimePicker
                                        key={idx}
                                        use12Hours
                                        format="h:mm a"
                                        value={dayjs(
                                            appointment[item],
                                            "HH:mm"
                                        )}
                                        onChange={(e) => {
                                            handleChangeSharingTime(
                                                item,
                                                e
                                            );
                                        }}
                                    />
                                )
                        )}
                    </VStack>
                </HStack>
                {/* 
                <Grid
                    templateColumns={
                        isLargerThan600 ? "repeat(6, 1fr)" : "repeat(1, 1fr)"
                    }
                    templateRows={
                        isLargerThan600 ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                    }
                    gap={6}
                >
                    <GridItem rowSpan={1} colSpan={1} gap={6}>
                        <VStack gap={6} align={"start"}>

                            <HStack
                                flexDirection={
                                    isLargerThan600 ? "row" : "column"
                                }
                                gap={isLargerThan600 ? "4" : "2"}
                                w={"full"}
                            >
                                <Box w={"full"}>
                                    <Text noOfLines={1} w={"full"}>
                                        Writing
                                    </Text>
                                </Box>
                            </HStack>
                            <HStack
                                flexDirection={
                                    isLargerThan600 ? "unset" : "column"
                                }
                                gap={isLargerThan600 ? "4" : "2"}
                                w={"full"}
                            >
                                <Box w={"full"}>
                                    <Text noOfLines={1} w={"full"}>
                                        Sharing
                                    </Text>
                                </Box>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem rowSpan={1} colSpan={5} gap={6}>
                        <VStack gap={6} align={"start"}>
                            <HStack
                                w={"full"}
                                justifyContent={"space-between"}
                                lineHeight={"40px"}
                            >
                                {weekDays.map((weekDay, index) => (
                                    <Text key={index}>{weekDay}</Text>
                                ))}
                            </HStack>


                        </VStack>
                    </GridItem>
                </Grid> */}
            </CardBody>
            <CardFooter>
                <Button
                    w="full"
                    colorScheme="blue"
                    leftIcon={<FaSave />}
                    onClick={handleSave}
                    isLoading={isSaving}
                >
                    Save
                </Button>
            </CardFooter>
        </Card>
    );
}

export default Appointment;
