import {
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    Heading,
    Text,
    useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API_URLS } from "../Constants";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { DialogueContext } from "../providers/DialogueProvider";
import DialogueEmotion from "../components/dialogue/dialogue-emotion";
import DialogueCategory from "../components/dialogue/dialogue-category";
import AudioDialogBox from "./AudioDialogBox";
import DDHeader from "../components/common/DDHeader";

function AudioDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const {
        onNext,
        onPrev,
        emotion,
        addFlag,
        setEmotion,
        setFeelingData,
        setContent,
        shareFlag,
        setAddFlag,
    } = useContext(DialogueContext);
    const { cookieAlive } = useContext(AuthContext);
    const [cookies] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const steps = [
        {
            title: "Emotion",
            description: "Choose Emotion Category",
        },
        {
            title: "Feelings",
            description: "Choose Feelings (Max 3)",
        },
        {
            title: "Audio",
            description: "Record Dialogue Audio",
        },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    useEffect(() => {
        // Check the audio is exist
        const getCheckExistAudio = async () => {
            setEmotion("");
            setFeelingData([]);
            setContent("");

            const response = await axios.post(
                shareFlag
                    ? API_URLS.DIALOGUE_GET_SHARE_APPT_AUDIO
                    : API_URLS.DIALOGUE_GET_SEL_APPT_AUDIO,
                {
                    appointId: location.state.apptId,
                    userEmail: cookies["dd_user_email"],
                }
            );

            if (response.data.audioPath) {
                setAddFlag(false);
                setEmotion(response.data.emotion);
                setFeelingData(JSON.parse(response.data.feelings));
                setContent(
                    `${process.env.REACT_APP_UPLOAD_URL}${response.data.audioPath}`
                );
            } else {
                setAddFlag(true);
            }
        };
        getCheckExistAudio();
    }, [shareFlag]);

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!location.state.apptId) {
            navigate("/");
        }
    }, [cookieAlive, navigate]);

    const handlePrev = () => {
        let step = activeStep;
        const prevResult = onPrev(step);
        if (!prevResult.result) {
            toast({
                title: "Record Dialogue",
                description: `${prevResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: "blue",
                color: "white",
            });
            return;
        }
        if (activeStep - 1 < 1) step = 1;
        else step = activeStep - 1;
        setActiveStep(step);
    };

    const handleNext = () => {
        let step = activeStep;
        const nextResult = onNext(step);
        if (!nextResult.result) {
            toast({
                title: "Record Dialogue",
                description: `${nextResult.msg}.`,
                duration: 3000,
                isClosable: true,
                bg: "blue",
                color: "white",
            });
            return;
        }
        if (activeStep + 1 > 3) {
            navigate("/");
        } else step = activeStep + 1;

        setActiveStep(step);
    };

    const handleBack = () => {
        navigate("/");
    };

    return (
        <VStack w={"full"}>
            {addFlag ? (
                <Card
                    w={isLargerThan830 ? "auto" : "-webkit-fill-available"}
                    minH={"400px"}
                    minW={isLargerThan830 ? "400px" : "350px"}
                    p={4}
                >
                    <CardHeader>
                        <DDHeader
                            title={"Audio Dialogue"}
                            activeStep={activeStep}
                            steps={steps}
                            question={location.state.question}
                            handleBack={handleBack}
                        />
                    </CardHeader>
                    <CardBody>
                        {activeStep === 1 && (
                            <DialogueEmotion handleEmotionSelect={handleNext} />
                        )}
                        {activeStep === 2 && <DialogueCategory />}
                        {activeStep === 3 && <AudioDialogBox type={"master"} />}
                    </CardBody>
                    <CardFooter>
                        {activeStep !== 1 && (
                            <HStack w={"full"}>
                                <Button
                                    onClick={handlePrev}
                                    disabled={activeStep === 1}
                                    _hover={{
                                        bg: "#3182ce",
                                        color: "white",
                                    }}
                                >
                                    Prev
                                </Button>
                                <Spacer />
                                <Button
                                    onClick={handleNext}
                                    disabled={activeStep === 3}
                                    _hover={{
                                        bg: "#3182ce",
                                        color: "white",
                                    }}
                                >
                                    {activeStep === 1
                                        ? "Next"
                                        : activeStep === 2
                                        ? "Next"
                                        : "Finish"}
                                </Button>
                            </HStack>
                        )}
                    </CardFooter>
                </Card>
            ) : (
                <Card
                    w={isLargerThan830 ? "auto" : "-webkit-fill-available"}
                    maxW={"665px"}
                    minH={"400px"}
                    minW={isLargerThan830 ? "400px" : "350px"}
                    p={4}
                >
                    <CardHeader>
                        <DDHeader
                            title={"Audio Review"}
                            steps={false}
                            handleBack={handleBack}
                        />
                    </CardHeader>
                    <CardBody>
                        <VStack w={"full"} gap={2} alignItems={"baseline"}>
                            <Heading fontSize="xl">Question</Heading>
                            <Text fontWeight={600}>
                                {location.state.question}
                            </Text>
                            <Heading fontSize="xl">Emotion</Heading>
                            <Text>
                                {emotion === "Yes" ? "Pleasant" : "Unpleasant"}
                            </Text>
                            <Heading fontSize="xl" mt={4}>
                                Audio
                            </Heading>
                            <AudioDialogBox type={"master"} />
                        </VStack>
                    </CardBody>
                </Card>
            )}
        </VStack>
    );
}

export default AudioDialogue;
