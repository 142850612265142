import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { API_URLS } from "../../Constants";
import { QuestionContext } from "../../providers/QuestionProvider";
import { RadioGroup, Stack, Radio, Container, useToast } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { AuthContext } from "../../providers/AuthProvider";
function AppointmentCategory() {
    const toast = useToast();
    const { cookieAlive } = useContext(AuthContext);
    const { categoryList, setCategoryList, currentCategory, setCurrentCategory } = useContext(QuestionContext);
    const [cookies] = useCookies(['dd_token', 'dd_user_id', 'dd_user_email', 'dd_spouse_email', 'dd_user_level']);
    useEffect(() => {
        const getCategoryList = async () => {
            try {
                const response = await axios.get(
                    API_URLS.DIALOGUE_QUESTION_CATEGORY_LIST,
                    {
                        params: {
                            email: cookieAlive()
                        }
                    }
                );
                if (response.data.success) {
                    setCategoryList(response.data.data);
                } else {
                    toast({
                        title: "Category and Questions",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        getCategoryList();
    }, [setCategoryList, toast]);
    return (
        <Container w={'full'} maxW={'full'}>
            <RadioGroup onChange={setCurrentCategory} value={currentCategory} id="question-category" w={'full'}>
                <Stack align={'start'} maxH="500px" overflow="auto" w={'full'} css={{
                    '&::-webkit-scrollbar': { width: '4px' },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' }
                }}>
                    {(() => {
                        if (categoryList && categoryList.length > 0) {
                            if (cookies['dd_user_level'] === 0) {
                                return categoryList.map((category, idx) => (
                                    <Radio value={category._id} key={idx} isDisabled>
                                        {category.category}
                                    </Radio>
                                ))
                            } else if (cookies['dd_user_level'] === 1) {
                                return categoryList.map((category, idx) => (
                                    <Radio value={category._id} key={idx} isDisabled={category.plan == 2 ? true : false}>
                                        {category.category}
                                    </Radio>
                                ))
                            } else if (cookies['dd_user_level'] === 2) {
                                return categoryList.map((category, idx) => (
                                    <Radio value={category._id} key={idx} isDisabled={category.plan == 2 ? true : false}>
                                        {category.category}
                                    </Radio>
                                ))
                            } else {
                                return categoryList.map((category, idx) => (
                                    <Radio value={category._id} key={idx}>
                                        {category.category}
                                    </Radio>
                                ))
                            }
                        }
                    })()}
                </Stack>
            </RadioGroup>
        </Container>
    );
}

export default AppointmentCategory;