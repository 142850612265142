import { createContext, useState } from "react";
import moment from "moment";
export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [contactInfo, setContactInfo] = useState({
        hFirstName: "",
        hLastName: "",
        hEmail: "",
        hNumber: "",
        wFirstName: "",
        wLastName: "",
        wEmail: "",
        wNumber: "",
    });
    const [appointment, setAppointment] = useState({
        Sun_writing: "12:00",
        Sun_sharing: "21:00",
        Mon_writing: "12:00",
        Mon_sharing: "21:00",
        Tue_writing: "12:00",
        Tue_sharing: "21:00",
        Wed_writing: "12:00",
        Wed_sharing: "21:00",
        Thu_writing: "12:00",
        Thu_sharing: "21:00",
        Fri_writing: "12:00",
        Fri_sharing: "21:00",
        Sat_writing: "12:00",
        Sat_sharing: "21:00",
    });
    const [timers, setTimers] = useState({
        write_time: 10, // 5 mins
        write_alarm: [
            {
                time: 2, // 2 mins
                enabled: true,
                unit: "m",
            },
            {
                time: 30, // 1 mins
                enabled: false,
                unit: "s",
            },
        ],
        audio_time: 2, // 5 mins
        audio_alarm: [
            {
                time: 30, // 1 min
                enabled: true,
                unit: "s",
            },
        ],
        video_time: 2, // 3 mins
        video_alarm: [
            {
                time: 30, // 1 min
                enabled: true,
                unit: "s",
            },
        ],
        share_time: 10, // 5 mins
        share_alarm: [
            {
                time: 2, // 2 mins
                enabled: true,
                unit: "m",
            },
            {
                time: 30, // 1 mins
                enabled: false,
                unit: "s",
            },
        ],
    });
    const [words, setWords] = useState({
        // appointment: "",
        // done: "",
        // share: "",
        email_appointment: "2",
        email_done: "1",
        email_share: "3",
    });

    const Init = () => {
        setContactInfo({
            hFirstName: "",
            hLastName: "",
            hEmail: "",
            hNumber: "",
            wFirstName: "",
            wLastName: "",
            wEmail: "",
            wNumber: "",
        });
        setAppointment({
            Sun_writing: "12:00",
            Sun_sharing: "21:00",
            Mon_writing: "12:00",
            Mon_sharing: "21:00",
            Tue_writing: "12:00",
            Tue_sharing: "21:00",
            Wed_writing: "12:00",
            Wed_sharing: "21:00",
            Thu_writing: "12:00",
            Thu_sharing: "21:00",
            Fri_writing: "12:00",
            Fri_sharing: "21:00",
            Sat_writing: "12:00",
            Sat_sharing: "21:00"
        });
        setTimers({
            write_time: 10, // 5 mins
            write_alarm: [
                {
                    time: 2, // 2 mins
                    enabled: true,
                    unit: "m",
                },
                {
                    time: 30, // 1 mins
                    enabled: false,
                    unit: "s",
                },
            ],
            audio_time: 2, // 5 mins
            audio_alarm: [
                {
                    time: 30, // 1 min
                    enabled: true,
                    unit: "s",
                },
            ],
            video_time: 2, // 3 mins
            video_alarm: [
                {
                    time: 30, // 1 min
                    enabled: true,
                    unit: "s",
                },
            ],
            share_time: 10, // 5 mins
            share_alarm: [
                {
                    time: 2, // 2 mins
                    enabled: true,
                    unit: "m",
                },
                {
                    time: 30, // 1 mins
                    enabled: false,
                    unit: "s",
                },
            ],
        });
        setWords({
            email_appointment: "2",
            email_done: "1",
            email_share: "3",
        });
    };

    const [subscription, setSubscription] = useState({
        date: moment(), // Use moment to initialize the date
        type: 0,
    });

    return (
        <AppContext.Provider
            value={{
                contactInfo,
                setContactInfo,
                appointment,
                setAppointment,
                timers,
                setTimers,
                words,
                setWords,
                subscription,
                setSubscription,
                Init,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
