import React, { createContext, useContext, useEffect, useState } from 'react';
export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [socketData, setSocketData] = useState({});

    useEffect(() => {
        const connectWebSocket = () => {
            const socket = new WebSocket(process.env.REACT_APP_SOCKET);
            console.log(process.env.REACT_APP_SOCKET)

            socket.onopen = () => {
                console.log('Connected to WebSocket server');
                setIsConnected(true);
            };

            socket.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    setSocketData(data);
                } catch (e) {

                }
            };

            socket.onclose = () => {
                console.log('Disconnected from WebSocket server');
                setIsConnected(false);
                setTimeout(connectWebSocket, 3000);
            };

            setWs(socket);
        };

        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);

    const sendMessage = (message) => {
        if (isConnected && ws) {
            ws.send(JSON.stringify(message));
        } else {
            console.log('WebSocket is not connected.');
        }
    };

    return (
        <SocketContext.Provider value={{ sendMessage, socketData, isConnected }}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;
export const useWebSocket = () => {
    return useContext(SocketContext);
};