import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const theme = extendTheme({
  styles: {
    global: (props) => ({
      'html, body': {
        bg: mode('gray.200', 'gray.900')(props)
      }
    })
  },
  components: {
    // Tabs: tabsTheme,
  },
  breakpoints : {
    xl: '1201px',
    md: '800px',
    sm: '440px',
    base: '0px',
  }
})

export default theme