import '../../ckeditor-styles.css'
import axios from "axios";
import { useCookies } from 'react-cookie';
import { useContext, useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import {
    Text, VStack, HStack, Box,
    Heading,
    useToast,
    Progress
} from "@chakra-ui/react";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { API_URLS } from "../../Constants";
import { DialogueContext } from "../../providers/DialogueProvider";
import { AuthContext } from "../../providers/AuthProvider";

const ShareDialogueContent = forwardRef(({ data, type, timerStatus, setTimerStatus }, ref) => {
    const [cookies] = useCookies();
    const { cookieAlive } = useContext(AuthContext);
    const { shareTimeEllapsed, setShareTimeEllapsed } = useContext(DialogueContext);
    const [curData, setCurData] = useState({});
    const [curVideoData, setCurVideoData] = useState({});
    const [curAudioData, setCurAudioData] = useState({});
    const [curLetterFlag, setCurLetterFlag] = useState(false);
    const [curVideoFlag, setCurVideoFlag] = useState(false);
    const [curAudioFlag, setCurAudioFlag] = useState(false);
    const [limitTime, setLimitTime] = useState(0);
    const [sortedShareAlarms, setSortedShareAlarms] = useState([]);
    const toast = useToast();
    const isMounted = useRef(true);
    const timerRef = useRef(null);
    useImperativeHandle(ref, () => ({
        stopTimer() {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        }
    }));

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Get about the time information
    useEffect(() => {
        const loadTimers = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.TIMERS_INFO, {
                    email: cookieAlive(),
                });

                if (response.data.success) {
                    const timers = response.data.data;
                    if (timers.share_time) {
                        setLimitTime(parseInt(timers.share_time) * 60);
                    }

                    if (timers.share_alarm && timers.share_alarm.length > 0) {
                        const sortedAlarms = timers.share_alarm.sort((a, b) => {
                            const timeA = a.unit === 'm' ? a.time * 60 : a.time;
                            const timeB = b.unit === 'm' ? b.time * 60 : b.time;
                            return timeA - timeB;
                        });

                        let tempSortedAlarmData = [];
                        for (let i = 0; i < sortedAlarms.length; i++) {
                            if (sortedAlarms[i].enabled === true) {
                                if (sortedAlarms[i].unit === "s") {
                                    tempSortedAlarmData.push(sortedAlarms[i].time);
                                } else {
                                    tempSortedAlarmData.push(sortedAlarms[i].time * 60);
                                }
                            }
                        }

                        setSortedShareAlarms(tempSortedAlarmData);
                    }
                } else {
                    if (isMounted.current) {
                        toast({
                            title: "Timer Information",
                            description: `${response.data.message}.`,
                            bg: 'blue',
                            color: 'white',
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadTimers();
    }, [cookieAlive, toast]);

    useEffect(() => {
        if (limitTime === 0 || timerStatus !== 1) return;
        const interval = setInterval(() => {
            setShareTimeEllapsed(prevTime => {
                if (prevTime >= limitTime) {
                    clearInterval(interval);
                    setTimeout(() => {
                        setTimerStatus(3);
                    }, 0);

                    if (isMounted.current) {
                        // Move toast call to a useEffect
                        setTimeout(() => {
                            toast({
                                title: "Time's up!",
                                description: "Sharing time is up.",
                                duration: 3000,
                                isClosable: true,
                                bg: 'blue',
                                color: 'white'
                            });
                        }, 0);
                    }

                    return limitTime;
                }

                const newTime = prevTime + 1;
                if (sortedShareAlarms.includes(newTime)) {
                    const audio = new Audio("./audio/alarm.mp3");
                    audio.play();
                }
                return newTime;
            });
        }, 1000);

        timerRef.current = interval;

        return () => clearInterval(interval);
    }, [limitTime, sortedShareAlarms, toast, timerStatus]); // Add addFlag to dependencies

    useEffect(() => {
        const checkShareLetter = async () => {
            // console.log(data._id, cookies["dd_user_email"], cookies["dd_spouse_email"])
            const response = await axios.post(API_URLS.DIALOGUE_GET_SEL_APPT_LETTER, {
                appointId: data._id,
                userEmail: type === "master" ? cookies["dd_user_email"] : cookies["dd_spouse_email"],
            });
            try {
                if (response.data.dialogue._id) {
                    setCurData(response.data.dialogue);
                    setCurLetterFlag(true);
                } else {
                    setCurData({});
                    setCurLetterFlag(false);
                }
            } catch (e) {
                setCurData({});
                setCurLetterFlag(false);
                console.log(e)
            }

        };

        const checkShareVideo = async () => {
            const response = await axios.post(API_URLS.DIALOGUE_GET_PRESHARE_APPT_VIDEO, {
                appointId: data._id,
                userEmail: type === "master" ? cookies["dd_user_email"] : cookies["dd_spouse_email"],
            });
            try {
                if (response.data._id) {
                    setCurVideoData(response.data);
                    setCurVideoFlag(true);
                } else {
                    setCurVideoData({});
                    setCurVideoFlag(false);
                }
            } catch (e) {
                setCurVideoData({});
                setCurVideoFlag(false);
                console.log(e)
            }

        };

        const checkShareAudio = async () => {
            const response = await axios.post(API_URLS.DIALOGUE_GET_PRESHARE_APPT_AUDIO, {
                appointId: data._id,
                userEmail: type === "master" ? cookies["dd_user_email"] : cookies["dd_spouse_email"],
            });
            try {
                if (response.data._id) {
                    setCurAudioData(response.data);
                    setCurAudioFlag(true);

                } else {
                    setCurAudioData({});
                    setCurAudioFlag(false);
                }
            } catch (e) {
                setCurVideoData({});
                setCurVideoFlag(false);
                console.log(e)
            }

        };

        checkShareLetter();
        checkShareVideo();
        checkShareAudio();
    }, [type, data]);
    return (
        <Box>
            <VStack w={'full'} justifyContent={'start'}>
                <HStack w={'full'}>
                    <Heading fontSize='md'>Emotion: </Heading>
                    {(() => {
                        if (curData) {
                            if (curLetterFlag) {
                                const emotion = curData.emotion == 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            } else if (curVideoFlag) {
                                const emotion = curVideoData.emotion == 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            } else if (curAudioFlag) {
                                const emotion = curAudioData.emotion == 'Yes' ? 'Pleasant' : 'Unpleasant';
                                return (
                                    <Text>{emotion}</Text>
                                )
                            }
                        }
                    })()}
                </HStack>
                {(() => {
                    if (curLetterFlag) {
                        return (
                            <Box w={'full'}>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curData && curData.feelings && curData.feelings.length > 0 && curData.feelings.map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={
                                        {
                                            plugins: [
                                                Essentials, Bold, Italic, Paragraph, Font,
                                                Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                                List, Indent, IndentBlock, Alignment, Link, AutoLink
                                            ],
                                        }
                                    }
                                    data={curData ? curData.letter : ''}
                                    disabled
                                    w={"full"}
                                    id="share-letter-editor"
                                />
                            </Box>

                        )
                    }
                })()}
                {(() => {
                    if (curVideoFlag) {
                        return (
                            <>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curVideoData && curVideoData.feelings && JSON.parse(curVideoData.feelings).length > 0 && JSON.parse(curVideoData.feelings).map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <video src={`${process.env.REACT_APP_UPLOAD_URL}${curVideoData.videoPath}`} style={{ minHeight: '250px' }} controls />
                            </>

                        )
                    }
                })()}
                {(() => {
                    if (curAudioFlag) {
                        return (
                            <>
                                <HStack w={"full"} justifyContent={'start'}>
                                    <Heading fontSize='md'>Feeling: </Heading>
                                    <Text>
                                        {curAudioData && curAudioData.feelings && JSON.parse(curAudioData.feelings).length > 0 && JSON.parse(curAudioData.feelings).map((feeling) => feeling.text).join(', ')}
                                    </Text>
                                </HStack>
                                <audio src={`${process.env.REACT_APP_UPLOAD_URL}${curAudioData.audioPath}`} style={{ minHeight: '250px' }} controls />
                            </>

                        )
                    }
                })()}
            </VStack>
            <VStack w={'full'} gap={2}>
                <HStack>
                    <Text>{`${String(Math.floor(shareTimeEllapsed / 60)).padStart(2, '0')}:${String(shareTimeEllapsed % 60).padStart(2, '0')}`}{`/`}{`${String(Math.floor(limitTime / 60)).padStart(2, '0')}:${String(limitTime % 60).padStart(2, '0')}`}</Text>
                </HStack>

                <HStack w={'full'}>
                    <Text fontSize='md'>Time: </Text>
                    <Progress w={'full'} max={limitTime} value={shareTimeEllapsed} colorScheme="blue" size='xs' />
                </HStack>
            </VStack>
        </Box>
    )
});

export default ShareDialogueContent;