import { useCookies } from "react-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useWebSocket } from "./SocketProvider";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const { socketData } = useWebSocket();
    const [cookies, setCookie, removeCookie] = useCookies([
        "dd_token",
        "dd_user_id",
        "dd_user_email",
        "dd_spouse_email",
        "dd_user_level",
    ]);

    useEffect(() => {
        if (socketData.type == "plan_update") {
            if (socketData.receiver.includes(cookies["dd_user_email"])) {
                logout();
            }
        }
    }, [socketData]);

    const saveUserInfoCookie = ({
        id,
        email,
        spouseEmail,
        token,
        level,
    }) => {
        setCookie("dd_token", token);
        setCookie("dd_user_id", id);
        // setCookie("dd_user_email", email);
        setCookie("dd_spouse_email", spouseEmail);
        setCookie("dd_user_level", level);
    };

    const logout = () => {
        setUser(null);
        Object.keys(cookies).forEach((cookieName) => removeCookie(cookieName));
    };

    const cookieAlive = () => {
        if (cookies["dd_user_email"]) return cookies["dd_user_email"];
        else return false;
    };

    return (
        <AuthContext.Provider
            value={{ user, saveUserInfoCookie, logout, cookieAlive }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
