import axios from "axios";
import { useContext, useMemo } from "react";
import { useState, useEffect } from "react";
import { API_URLS } from "../../Constants";
import { DialogueContext } from "../../providers/DialogueProvider";
import { Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, VStack, Checkbox, useToast, Box } from "@chakra-ui/react";

function DialogueCategory() {
    const toast = useToast();
    const [selFeelings, setSelFeelings] = useState([]);
    const [feelingList, setFeelingList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();

    const { feelingCategory, setFeelingCategory, feelingData, setFeelingData, emotion } = useContext(DialogueContext);

    useEffect(() => {
        const getCategoryList = async () => {
            try {
                const response = await axios.get(
                    API_URLS.DIALOGUE_FEELING_CATEGORY_LIST,
                    {
                        params: {
                            emotion: emotion === 'Yes' ? "1" : "2"
                        }
                    }
                );
                if (response.data.success) {
                    setFeelingCategory(response.data.data);
                } else {
                    toast({
                        title: "Category and Feelings",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        getCategoryList();

        if (feelingData.length > 0)
            setSelFeelings(feelingData);
    }, [setFeelingCategory, toast, feelingData]);

    const handleChangeFeeling = (checked, feeling, parentIndex, curIndex) => {
        if (checked) {
            const addData = {
                text: feeling,
                parentIndex: parentIndex,
                curIndex: curIndex,
            }
            let updateData = [...selFeelings, addData];
            setSelFeelings(updateData);
            setFeelingData(updateData);
        } else {
            const updatedSelFeelings = selFeelings.filter(item =>
                item.text !== feeling || item.curIndex !== curIndex || item.parentIndex !== parentIndex

            );
            setSelFeelings(updatedSelFeelings);
            setFeelingData(updatedSelFeelings);
        }
    }

    const handleCategoryButton = useMemo(() => {
        const fetchFeelings = async () => {
            try {
                const response = await axios.get(API_URLS.DIALOGUE_FEELINGS_LIST, {
                    params: { categoryId: selectedCategory, emotion: emotion === 'Yes' ? "1" : "2" }
                });
                if (response.data.success) {
                    setFeelingList(response.data.data); // Update feeling data with the fetched data
                } else {
                    toast({
                        title: "Dialogue Category",
                        description: `${response.data.message}.`,
                        bg: 'red',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                toast({
                    title: "Dialogue Category",
                    description: `${e.message}.`,
                    bg: 'red',
                    color: 'white',
                    duration: 3000,
                    isClosable: true,
                });
                console.error(e);
            }
        };
        fetchFeelings();
    }, [setFeelingList, selectedCategory, toast]);

    const handleChangeCategory = (categoryId) => {
        setSelectedCategory(categoryId);
    }

    return (
        <VStack w={'full'}>
            <Box w={'full'} lineHeight={'40px'} height={'40px'}>
                <Text fontSize='md' w={'full'} textAlign={'center'} fontWeight={600} lineHeight={'40px'} height={'40px'}>
                    {feelingData && feelingData.length > 0 && feelingData.map(feeling => feeling.text).join(', ')}
                </Text>
            </Box>

            <Accordion maxH={'330px'} overflowY={'scroll'} w={'full'}
                allowToggle
                css={{
                    '&::-webkit-scrollbar': { width: '4px' },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' }
                }}>
                {feelingCategory && feelingCategory.length > 0 && feelingCategory.map((item, idx) => (
                    <AccordionItem key={idx}>
                        <AccordionButton _hover={{ bg: '#3182ce', color: 'white' }} borderRadius={'4px'} onClick={() => {
                            handleChangeCategory(item._id);
                        }}>
                            {item.category}
                        </AccordionButton>
                        <AccordionPanel>
                            <VStack align={'start'} pl={6}>
                                {feelingList.map((feeling, idxx) => (
                                    <Checkbox
                                        key={idxx}
                                        onChange={(e) => { handleChangeFeeling(e.target.checked, feeling.feelings, idx, idxx) }}
                                        isDisabled={!selFeelings.some(item => item.text === feeling.feelings) && selFeelings.length >= 3}
                                        // isDisabled={!selFeelings.some(itemcategory === feeling) && selFeelings.length >= 3}
                                        isChecked={selFeelings.some(item => item.text === feeling.feelings)}
                                        _hover={{ bg: '#3182ce', color: 'white' }}
                                        px={4}
                                        borderRadius={'4px'}
                                    >
                                        {feeling.feelings}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </VStack>
    );
}

export default DialogueCategory;