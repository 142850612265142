import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
    useToast,
    HStack,
    Text,
    useMediaQuery,
    Box,
    Button,
    Select,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    VStack,
    Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useContext, useState, useRef } from "react";
import { useMediaCapture, BlobAudio } from "react-media-capture";

import { API_URLS } from "../Constants.jsx";
import { AuthContext } from "../providers/AuthProvider.jsx";
import { DialogueContext } from "../providers/DialogueProvider.jsx";
import micImg from "../assets/imgs/mic.png";
import micBannedImg from "../assets/imgs/micBanned.webp";

export default function AudioDialogBox({}) {
    const {
        status,
        capturedAudio,
        devices,
        duration,
        volume,
        selectedDeviceId,
        lastError,
        record,
        pause,
        resume,
        stop,
        clear,
        selectDevice,
    } = useMediaCapture({ video: false, watchVolume: true });
    const classNames = ["video-viewport", status];

    const navigate = useNavigate();
    const [cookies] = useCookies();
    const toast = useToast();
    const { cookieAlive } = useContext(AuthContext);
    const [makeTimeEllapsed, setMakeTimeEllapsed] = useState(0);
    const [limitTime, setLimitData] = useState(0);
    const [sortedAlarms, setSortedAlarms] = useState([]);
    const [isPause, setIsPause] = useState(false);
    const isMounted = useRef(true);
    const timerRef = useRef(null);
    const location = useLocation();
    const {
        content,
        setContent,
        emotion,
        feelingData
    } = useContext(DialogueContext);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isSmallerThan750] = useMediaQuery("(max-width: 750px)");

    const onStart = useCallback(() => {
        setMakeTimeEllapsed(0);
        const options = {
            audioMIMEType: "audio/webm",
            audioBitsPerSecond: 128000,
        };
        record(options);

        // Start the timer event if not already running
        if (limitTime > 0 && !timerRef.current) {
            if (!isPause) {
                const interval = setInterval(() => {
                    setMakeTimeEllapsed((prevTime) => {
                        if (prevTime >= limitTime) {
                            clearInterval(interval);

                            if (isMounted.current) {
                                stop();

                                setTimeout(() => {
                                    toast({
                                        title: "Time's up!",
                                        description:
                                            "You have reached the deadline.",
                                        duration: 3000,
                                        isClosable: true,
                                        bg: "blue",
                                        color: "white",
                                    });
                                }, 0);
                            }

                            return limitTime;
                        }

                        const newTime = prevTime + 1;
                        if (sortedAlarms.includes(newTime)) {
                            const audio = new Audio("./audio/alarm.mp3");
                            audio.play();
                        }

                        return newTime;
                    });
                }, 1000);
                timerRef.current = interval;
            } else if (timerRef.current) {
                clearInterval(timerRef.current);
            }

            return () => clearInterval(timerRef.current);
        }
    }, [record, limitTime, sortedAlarms, toast, stop]);
    // Save the audio
    const handleSaveAudio = async () => {
        
        const formData = new FormData();
        formData.append("audio", capturedAudio.blob, "audio.webm");
        formData.append("appointmentId", location.state.apptId);
        formData.append("appointmentDate", location.state.apptDate);
        formData.append("userId", cookies["dd_user_id"]);
        formData.append("userEmail", cookies["dd_user_email"]);
        formData.append("spouseEmail", cookies["dd_spouse_email"]);
        formData.append("emotion", emotion);
        formData.append("feelings", JSON.stringify(feelingData));

        try {
            const response = await axios.post(
                API_URLS.DIALOGUE_ADD_AUDIO,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.success) {
                setContent(
                    `${process.env.REACT_APP_UPLOAD_URL}${response.data.path}`
                );
                toast({
                    title: "Dialogue Audio",
                    description: "Your audio has been uploaded successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                onClose();
            } else {
                toast({
                    title: "Dialogue Audio",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: "Dialogue Audio",
                description: `${e.message}.`,
                bg: "blue",
                color: "white",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const onPause = useCallback(() => {
        pause();
        setIsPause(true);
    }, [pause]);

    const onResume = useCallback(() => {
        resume();
        setIsPause(false);
    }, [resume]);

    const onStop = useCallback(() => {
        stop();

        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [stop]);
    const onRetake = useCallback(() => clear(), [clear]);
    const onAccept = useCallback(() => {
        onOpen();
    }, []);

    useEffect(() => {
        if (lastError) {
            if (lastError.message.includes("Requested device not found")) {
                toast({
                    title: "Device Error",
                    description:
                        "The selected audio device is not available. Please check your device settings.",
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Audio",
                    description:
                        typeof lastError === "string"
                            ? lastError
                            : "An unknown error occurred.",
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }, [lastError, toast]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!location.state.apptId) {
            navigate("/");
        }
    }, [navigate]);

    // Get about the time information
    useEffect(() => {
        const loadTimers = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.TIMERS_INFO, {
                    email: cookieAlive(),
                });

                if (response.data.success) {
                    const timers = response.data.data;

                    if (timers.audio_time) {
                        setLimitData(parseInt(timers.audio_time) * 60);
                    }

                    if (timers.audio_alarm && timers.audio_alarm.length > 0) {
                        const sortedAlarms = timers.audio_alarm.sort((a, b) => {
                            const timeA = a.unit === "m" ? a.time * 60 : a.time;
                            const timeB = b.unit === "m" ? b.time * 60 : b.time;
                            return timeA - timeB;
                        });

                        let tempSortedAlarmData = [];
                        for (let i = 0; i < sortedAlarms.length; i++) {
                            if (sortedAlarms[i].enabled === true) {
                                if (sortedAlarms[i].unit === "s") {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time
                                    );
                                } else {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time * 60
                                    );
                                }
                            }
                        }

                        setSortedAlarms(tempSortedAlarmData);
                    }
                } else {
                    if (isMounted.current) {
                        toast({
                            title: "Timer Information",
                            description: `${response.data.message}.`,
                            bg: "blue",
                            color: "white",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadTimers();
    }, [cookieAlive, toast]);

    return (
        <VStack w={"full"}>
            <AlertDialog isOpen={isOpen} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent mx={8}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Save Audio Dialogue
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to save this audio dialogue?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button
                                colorScheme="blue"
                                onClick={(e) => {
                                    handleSaveAudio();
                                }}
                                ml={3}
                            >
                                Save
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <HStack
                w={"full"}
                alignContent={"center"}
                justifyContent={"center"}
            >
                {feelingData &&
                    feelingData.length > 0 &&
                    feelingData.map((feeling, idx) => (
                        <Text key={idx} fontSize={16} fontWeight={600}>
                            {feeling.text}
                        </Text>
                    ))}
            </HStack>

            <VStack w={"full"}>
                {content ? (
                    <audio
                        className="audio-full-width"
                        src={content}
                        controls
                    />
                ) : (
                    <VStack className="controls" mt={4} w={"full"}>
                        <VStack
                            alignItems={"center"}
                            w={"full"}
                            justifyContent={"center"}
                        >
                            {(() => {
                                switch (status) {
                                    case "previewing":
                                    case "recording":
                                    case "paused":
                                    case "acquiring":
                                        return (
                                            <Image
                                                src={micImg}
                                                maxH={"200px"}
                                                w={"auto"}
                                            />
                                        );
                                    case "denied":
                                        return (
                                            <Image
                                                src={micBannedImg}
                                                maxH={"200px"}
                                                w={"auto"}
                                            />
                                        );
                                    case "recorded":
                                        return (
                                            <BlobAudio
                                                srcObject={capturedAudio.blob}
                                                controls
                                                className="audio-full-width"
                                            />
                                        );
                                    default:
                                }
                            })()}
                        </VStack>
                        {(() => {
                            if (duration !== undefined) {
                                const seconds = duration / 1000;
                                const hh = Math.floor(seconds / 3600)
                                    .toString()
                                    .padStart(2, "0");
                                const mm = Math.floor((seconds / 60) % 60)
                                    .toString()
                                    .padStart(2, "0");
                                const ss = Math.floor(seconds % 60)
                                    .toString()
                                    .padStart(2, "0");
                                return (
                                    <HStack>
                                        <Box className="duration">{`${hh}:${mm}:${ss}`}</Box>
                                        <Box className="duration">{`${"00"}:${(
                                            limitTime / 60
                                        )
                                            .toString()
                                            .padStart(2, "0")}:${"00"}`}</Box>
                                    </HStack>
                                );
                            } else {
                                if (!devices || devices.length <= 1) {
                                    return <Box />;
                                } else {
                                    return (
                                        <Select
                                            onChange={(evt) =>
                                                selectDevice(evt.target.value)
                                            }
                                            value={selectedDeviceId}
                                        >
                                            {devices.map(({ label, id }) => {
                                                label = label.replace(
                                                    /\([0-9a-f]{4}:[0-9a-f]{4}\)/,
                                                    ""
                                                );
                                                return (
                                                    <option value={id} key={id}>
                                                        {label}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    );
                                }
                            }
                        })()}
                        {(() => {
                            switch (status) {
                                case "acquiring":
                                case "previewing":
                                    return (
                                        <VStack className="buttons">
                                            <Button
                                                onClick={onStart}
                                                disabled={
                                                    status !== "previewing"
                                                }
                                                colorScheme="blue"
                                            >
                                                Start
                                            </Button>
                                        </VStack>
                                    );
                                case "denied":
                                    return (
                                        <VStack className="buttons">
                                            <Text>
                                                Please enable the microphone in
                                                your browser.
                                            </Text>
                                        </VStack>
                                    );
                                case "recording":
                                    return (
                                        <HStack className="buttons">
                                            <Button onClick={onPause}>
                                                Pause
                                            </Button>
                                            <Button onClick={onStop}>
                                                Stop
                                            </Button>
                                        </HStack>
                                    );
                                case "paused":
                                    return (
                                        <HStack
                                            className="buttons"
                                            style={{
                                                display: isSmallerThan750
                                                    ? "flex"
                                                    : "block",
                                                justifyContent: isSmallerThan750
                                                    ? "center"
                                                    : "unset",
                                            }}
                                        >
                                            <Button onClick={onResume}>
                                                Resume
                                            </Button>
                                            <Button onClick={onStop}>
                                                Stop
                                            </Button>
                                        </HStack>
                                    );
                                case "recorded":
                                    return (
                                        <HStack className="buttons">
                                            <Button onClick={onRetake}>
                                                Retake
                                            </Button>
                                            <Button
                                                onClick={onAccept}
                                                disabled={status !== "recorded"}
                                            >
                                                Save
                                            </Button>
                                        </HStack>
                                    );
                                default:
                            }
                        })()}
                    </VStack>
                )}
            </VStack>
        </VStack>
    );
}
