import '../ckeditor-styles.css'
import {
    VStack,
    useSteps,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    HStack,
    Button,
    Spacer,
    useToast,
    useMediaQuery,
    Text
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API_URLS } from "../Constants";
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import DDHeader from "../components/common/DDHeader";
import ShareDialogueContent from "../components/history/ShareDialogueContent";
import { useWebSocket } from "../providers/SocketProvider";
import { DialogueContext } from "../providers/DialogueProvider";

function ShareDialogue() {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { cookieAlive } = useContext(AuthContext);
    const { sendMessage, socketData } = useWebSocket();
    const [cookies] = useCookies();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [timerStatus, setTimerStatus] = useState(0); // 0: Stopped, 1: Running, 2: Paused, 3: Up
    const { shareTimeEllapsed, setShareTimeEllapsed } = useContext(DialogueContext);
    const [selectedRole, setSelectedRole] = useState("husband");
    const [appointment, setAppointment] = useState(null);
    const [curType, setCurtype] = useState("");
    
    const steps = [
        {
            title: "Emotion",
        },
        {
            title: "Feelings",
        },
        {
            title: "Make",
        },
        {
            title: "Share",
        },
    ];
    const dialogueLetterRef = useRef(null);

    const stopDialogueLetterTimer = () => {
        if (dialogueLetterRef.current) {
            dialogueLetterRef.current.stopTimer();
        }
    };

    const { activeStep } = useSteps({
        index: 4,
        count: steps.length,
    });

    const getAppointment = async (type) => {
        try {
            const response = await axios.post(
                API_URLS.DIALOGUE_GET_PRESHARE_APPT,
                {
                    appointmentId: location.state.dataId,
                }
            );
            if (response.data.data) {
                setAppointment(response.data.data);
                // setTimerStatus(0);
            } else {
                toast({
                    title: "History",
                    description: `${response.data.message}.`,
                    duration: 3000,
                    isClosable: true,
                    bg: "blue",
                    color: "white",
                });
                setAppointment({});
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        if (!location.state.apptId) {
            navigate("/");
        }

        if (cookies["dd_user_email"] == cookies["dd_h_email"]) {
            handleWife();
        } else if (cookies["dd_user_email"] == cookies["dd_w_email"]) {
            handleHusband();
        }
    }, [cookieAlive, navigate]);

    const handleBack = () => {
        navigate("/");
    };

    const handleStart = useCallback(async () => {
        if (timerStatus == 0) {
            try {
                const response = await axios.post(
                    API_URLS.DIALOGUE_GET_PRESHARE_APPT,
                    {
                        appointmentId: location.state.dataId,
                    }
                );

                if (response.data.success) {
                    if (
                        response.data.data["preshareMain"] &&
                        response.data.data["preshareSpouse"]
                    ) {
                        if (timerStatus == 0) {
                            setTimerStatus(1);
                            sendMessage({
                                sender: cookies["dd_user_email"],
                                receiver: [
                                    cookies["dd_w_email"],
                                    cookies["dd_h_email"],
                                ],
                                type: "share",
                                expect: 1,
                                time: shareTimeEllapsed
                            });
                        }
                    } else {
                        toast({
                            title: "Share dialogue",
                            description: `Your spouse isn't ready to share yet.`,
                            bg: "blue",
                            color: "white",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            if (timerStatus == 1) {
                setTimerStatus(2);
                sendMessage({
                    sender: cookies["dd_user_email"],
                    receiver: [cookies["dd_w_email"], cookies["dd_h_email"]],
                    type: "share",
                    expect: 2,
                    time: shareTimeEllapsed
                });
            } else if (timerStatus == 2) {
                setTimerStatus(1);
                sendMessage({
                    sender: cookies["dd_user_email"],
                    receiver: [cookies["dd_w_email"], cookies["dd_h_email"]],
                    type: "share",
                    expect: 1,
                    time: shareTimeEllapsed
                });
            }
        }
    });

    const handleFinish = useCallback(async () => {
        try {
            const response = await axios.post(API_URLS.DIALOGUE_SHARE_APPT, {
                appointmentId: location.state.dataId,
                userEmail: cookies["dd_user_email"],
            });
            if (response.data.success) {
                setTimerStatus(3);
                sendMessage({
                    sender: cookies["dd_user_email"],
                    receiver: [cookies["dd_w_email"], cookies["dd_h_email"]],
                    type: "share",
                    expect: 3,
                    time: shareTimeEllapsed
                });
                stopDialogueLetterTimer();
                navigate("/history");
            }
        } catch (e) {
            console.log(e);
        }
    });

    useEffect(() => {
        if (socketData.type == "share") {
            if (socketData.receiver.includes(cookies["dd_user_email"])) {
                setTimerStatus(socketData.expect);
                setShareTimeEllapsed(socketData.time);
            }
        } else if (socketData.type == "ready_share") {
            if (socketData.receiver.includes(cookies["dd_user_email"])) {
                setTimerStatus(socketData.expect);
            }
        }
    }, [socketData]);

    const handleTimerStatus = useCallback((status) => {
        setTimerStatus(status);
        sendMessage({
            sender: cookies["dd_user_email"],
            receiver: [cookies["dd_w_email"], cookies["dd_h_email"]],
            type: "share",
            expect: status,
            time: shareTimeEllapsed
        });
    });

    const handleWife = useCallback(() => {
        setSelectedRole("wife");
        if (cookies["dd_w_email"] === cookies["dd_user_email"]) {
            getAppointment("master");
            setCurtype("master");
        } else if (cookies["dd_h_email"] === cookies["dd_user_email"]) {
            getAppointment("spouse");
            setCurtype("spouse");
        }
    });

    const handleHusband = useCallback(() => {
        setSelectedRole("husband");
        if (cookies["dd_h_email"] === cookies["dd_user_email"]) {
            getAppointment("master");
            setCurtype("master");
        } else if (cookies["dd_w_email"] === cookies["dd_user_email"]) {
            getAppointment("spouse");
            setCurtype("spouse");
        }
    });

    return (
        <VStack p={8}>
            <Card
                w={isLargerThan830 ? "auto" : "full"}
                minH={"400px"}
                minW={isLargerThan830 ? "665px" : "350px"}
                p={4}
            >
                <CardHeader>
                    <VStack w={"full"} gap={4}>
                        <DDHeader
                            title={"Sharing"}
                            activeStep={activeStep}
                            steps={steps}
                            question={appointment ? appointment.question : ""}
                            handleBack={handleBack}
                        />
                        <HStack justifyContent={"center"} w={"full"}>
                            <Button
                                colorScheme={
                                    selectedRole === "husband" ? "blue" : "gray"
                                }
                                px={8}
                                onClick={(e) => {
                                    handleHusband();
                                }}
                            >
                                Husband
                            </Button>
                            <Button
                                colorScheme={
                                    selectedRole === "wife" ? "blue" : "gray"
                                }
                                px={8}
                                onClick={(e) => {
                                    handleWife();
                                }}
                            >
                                Wife
                            </Button>
                        </HStack>
                    </VStack>
                </CardHeader>
                <CardBody>
                    {appointment && (
                        <ShareDialogueContent
                            ref={dialogueLetterRef}
                            timerStatus={timerStatus}
                            setTimerStatus={handleTimerStatus}
                            type={curType}
                            data={appointment}
                        />
                    )}
                    {!appointment && <Text w={'full'} textAlign='center'>No content or Not shared</Text>}
                </CardBody>
                <CardFooter>
                    {activeStep !== 1 && (
                        <HStack w={"full"}>
                            <Button
                                onClick={() => {
                                    handleStart();
                                }}
                                colorScheme={timerStatus == 3 ? "gray" : "blue"}
                                disabled={timerStatus == 3}
                                _hover={{
                                    bg:
                                        timerStatus == 3
                                            ? "inherit"
                                            : "#3182ce",
                                    color:
                                        timerStatus == 3 ? "inherit" : "white",
                                }}
                            >
                                {timerStatus == 0
                                    ? "Start"
                                    : timerStatus == 1
                                    ? "Pause"
                                    : timerStatus == 2
                                    ? "Resume"
                                    : "Start"}
                            </Button>
                            <Spacer />

                            <Button
                                onClick={() => {
                                    handleFinish();
                                }}
                                colorScheme="blue"
                                _hover={{
                                    bg: "#3182ce",
                                    color: "white",
                                }}
                            >
                                End
                            </Button>
                        </HStack>
                    )}
                </CardFooter>
            </Card>
        </VStack>
    );
}

export default ShareDialogue;
