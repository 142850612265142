// export const numberFormat = (val) => {
//     return val > 9 ? val : "0" + val;
// };

// export const timeFormat = (val, showNoon = false) => {
//     if (showNoon)
//         return `${numberFormat(val[0])}:${numberFormat(val[1])} ${
//             val[2] === 0 ? " AM" : " PM"
//         }`;
//     return numberFormat(val[0] + val[2] * 12) === 24
//         ? numberFormat(12) + ":" + numberFormat(val[1])
//         : numberFormat(val[0] + val[2] * 12) + ":" + numberFormat(val[1]);
// };

// export const extractTimeArray = (timeString) => {
//     const [hours, minutes] = timeString.split(":").map(Number);
//     const adjustedHours = hours % 12 || 12;
//     const noonInfo = hours >= 12 ? 1 : 0;
//     return [adjustedHours, Number(minutes), noonInfo];
// };

export function constrainSize(liveVideo, { width, height }) {
    if (liveVideo) {
        width = liveVideo.width;
        height = liveVideo.height;
    }
    const html = document.body.parentNode;
    const availableWidth = html.clientWidth - 50;
    const availableHeight = html.clientHeight - 100;
    if (width > availableWidth) {
        height = Math.round((height * availableWidth) / width);
        width = availableWidth;
    }
    if (height > availableHeight) {
        width = Math.round((width * availableHeight) / height);
        height = availableHeight;
    }
    return { width, height };
}

// export function formatDate(date) {
//     const d = new Date(date);
//     let month = "" + (d.getMonth() + 1);
//     let day = "" + d.getDate();
//     const year = d.getFullYear();

//     if (month.length < 2) month = "0" + month;
//     if (day.length < 2) day = "0" + day;

//     return [year, month, day].join("-");
// }
