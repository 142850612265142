import {
    Box,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepTitle,
    StepDescription,
    StepIcon,
    StepNumber,
    StepSeparator,
    useMediaQuery
} from "@chakra-ui/react";
const DDStepper = ({activeStep = 1, steps = []}) => {
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    return (
        <Stepper
            index={activeStep}
            flexDirection={isLargerThan830 ? "row" : "column !important"}
            alignItems={isLargerThan830 ? "center" : "start !important"}
            paddingLeft={isLargerThan830 ? "0" : "15px !important"}
            w={'full'}
        >
            {steps && steps.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>
                            {step.description}
                        </StepDescription>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    )
}

export default DDStepper;