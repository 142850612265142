import {
    Card,
    CardBody,
    CardHeader,
    useToast,
    AspectRatio,
    Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";

function Correctly(props) {
    const [videoLink, setVideoLink] = useState("");
    const toast = useToast();
    useEffect(() => {
        const loadVideo = async () => {
            try {
                const response = await axios.get(API_URLS.MATERIAL_VIDEOS);
                if (response.data.success) {
                    const newLink = `${process.env.REACT_APP_UPLOAD_URL}uploads/video/${response.data.data[0]["correctlyLink"]}`
                    setVideoLink(newLink);
                } else {
                    toast({
                        title: "How to dialogue correctly",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadVideo();
    }, [toast]);
    return (
        <Card minH={"600px"} w={"full"}>
            <CardHeader>{props.title}</CardHeader>
            <CardBody>
                <Box w={'full'}>
                    {videoLink && (
                        <AspectRatio ratio={16/9} w={'full'}>
                            <iframe
                                title="How to dialogue correctly"
                                src={videoLink}
                                allowFullScreen
                            ></iframe>
                        </AspectRatio>
                    )}
                </Box>

            </CardBody>
        </Card>
    );
}

export default Correctly;
