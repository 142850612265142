import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Grid,
    GridItem,
    Text,
    VStack,
    useDisclosure,
    useMediaQuery,
    useToast
} from "@chakra-ui/react";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    FaBriefcase,
    FaClock,
    FaEnvelope,
    FaKey,
    FaUser,
} from "react-icons/fa";
import ContactInfo from "../components/settings/ContactInfo";
import { AuthContext } from "../providers/AuthProvider";
import Appointment from "../components/settings/Appointment";
import Timers from "../components/settings/Timers";
import Words from "../components/settings/Words";
import Password from "../components/settings/Password";
import { useCookies } from "react-cookie";
import { API_URLS } from "../Constants";
import axios from "axios";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react'
function Settings() {
    const navigate = useNavigate();
    const { cookieAlive } = useContext(AuthContext);
    const [contentOpened, setContentOpened] = useState(1);
    const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
    const [isSending, setIsSending] = useState(false);
    const [cookies] = useCookies();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [plan, setPlan] = useState('');

    const cancelRef = useRef()
    const handleContentHeader = (title, itemIdx) => {
        if (contentOpened == 1) {
            if (!cookies['dd_user_email'] || !cookies['dd_spouse_email']) {
                toast({
                    title: "More information",
                    description: `Please complete ALL the settings.`,
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                });
                return;
            }
        }
        setContentOpened(itemIdx);
    };

    const handleCancel = async () => {
        if (cookieAlive()) {
            try {
                setIsSending(true);
                const response = await axios.post(API_URLS.CANCEL_SUBSCRIPTION, {
                    email: cookieAlive()
                });

                if (response.data.success) {
                    toast({
                        title: "Cancel Subscription",
                        description: ``,
                        status: "info",
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Cancel Subscription",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                        color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsSending(false);
            }
        } else {

        }

    }

    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        switch (cookies['dd_user_level']) {
            case '0':
            case 0:
                setPlan('Inactive');
                break;
            case '1':
            case 1:
                setPlan('Free');
                break;
            case '2':
            case 2:
                setPlan('Post');
                break;
            case '3':
            case 3:
                setPlan('Monthly');
                break;
            case '4':
            case 4:
                setPlan('Annual');
                break;
            case '5':
            case 5:
                setPlan('Lifetime');
                break;
            case '6':
            case 6:
                setPlan('Complimentary');
                break;
            case '-1':
            case -1:
                setPlan('Cancelled');
                break;
        }
    }, [cookieAlive, navigate]);

    return (
        <Box w={"full"}>
            <Grid
                templateRows={"repeat(1, 1fr)"}
                templateColumns={isLargerThan900 ? "repeat(4, 1fr)" : "repeat(1, 1fr)"}
                gap={isLargerThan900 ? 4 : 0}
                mx={4}
            >
                <GridItem rowSpan={1} colSpan={1} w={"full"} mb={isLargerThan900 ? 0 : 4}>
                    <Card w={"full"} minH={"600px"}>
                        <CardHeader>Settings</CardHeader>
                        <CardBody>
                            <VStack w={"full"} align={"start"}>
                                <Button
                                    leftIcon={<FaUser />}
                                    colorScheme={
                                        contentOpened === 1 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            1
                                        )
                                    }
                                >
                                    Contact Information
                                </Button>
                                <Button
                                    leftIcon={<FaBriefcase />}
                                    colorScheme={
                                        contentOpened === 2 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            2
                                        )
                                    }
                                >
                                    Appointments
                                </Button>
                                <Button
                                    leftIcon={<FaClock />}
                                    colorScheme={
                                        contentOpened === 3 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            3
                                        )
                                    }
                                >
                                    Timers
                                </Button>
                                <Button
                                    leftIcon={<FaEnvelope />}
                                    colorScheme={
                                        contentOpened === 4 ? "blue" : "gray"
                                    }
                                    w={"full"}
                                    onClick={(e) =>
                                        handleContentHeader(
                                            e.target.innerText,
                                            4
                                        )
                                    }
                                >
                                    Emails
                                </Button>
                            </VStack>
                        </CardBody>
                        <CardHeader display={"flex"} gap={2}>Subscription:&nbsp;<Text color={'red'} fontWeight={'800'}>{plan}</Text></CardHeader>
                        <CardBody>
                            <Button
                                leftIcon={<FaUser />}
                                w={"full"}
                                onClick={(e) => {
                                    window.open(
                                        "https://dialoguedaily.com/pricing",
                                        "_blank"
                                    )
                                }}
                                mb={4}
                            >
                                Upgrade
                            </Button>
                            <Button
                                leftIcon={<FaUser />}
                                w={"full"}
                                onClick={(e) => {
                                    onOpen();
                                }}
                            >
                                Cancel
                            </Button>
                        </CardBody>
                        <CardHeader>Security</CardHeader>
                        <CardBody>
                            <Button
                                leftIcon={<FaKey />}
                                colorScheme={
                                    contentOpened === 5 ? "blue" : "gray"
                                }
                                w={"full"}
                                onClick={(e) =>
                                    handleContentHeader(e.target.innerText, 5)
                                }
                            >
                                Reset Password
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem rowSpan={1} colSpan={3} w={"full"}>
                    {contentOpened === 1 && <ContactInfo />}
                    {contentOpened === 2 && <Appointment />}
                    {contentOpened === 3 && <Timers />}
                    {contentOpened === 4 && <Words />}
                    {contentOpened === 5 && <Password />}
                </GridItem>
            </Grid>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Cancel Subscription
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to cancel the subscription?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleCancel();
                                onClose();
                            }} ml={3}>
                                Submit
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
}

export default Settings;
