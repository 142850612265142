
import 'ckeditor5/ckeditor5.css'
import '../../ckeditor-styles.css'
import axios from "axios";
import { API_URLS } from "../../Constants";
import { AuthContext } from "../../providers/AuthProvider";
import { DialogueContext } from "../../providers/DialogueProvider";
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react'
import {
    ClassicEditor,
    Context,
    SourceEditing,
    Alignment,
    Bold,
    Essentials,
    Italic,
    Paragraph,
    Link,
    FileRepository,
    Font,
    Image,
    ImageToolbar,
    ImageCaption,
    ImageStyle,
    ImageResize,
    ImageInsert,
    ContextWatchdog,
    List,
    Indent,
    IndentBlock,
    SimpleUploadAdapter,
    AutoImage,
} from 'ckeditor5'

import {
    HStack,
    useToast,
    Text,
    Progress,
    Box,
    VStack,
    useMediaQuery,
    Container,
} from "@chakra-ui/react";
import {
    useContext,
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from "react";

const DialogueLetter = forwardRef((props, ref) => {
    const toast = useToast();
    const [isLargerThan830] = useMediaQuery("(min-width: 830px)");
    const { cookieAlive } = useContext(AuthContext);
    const {
        content,
        setContent,
        addFlag,
        feelingData,
        makeTimeEllapsed,
        setMakeTimeEllapsed,
    } = useContext(DialogueContext);
    const [limitTime, setLimitData] = useState(0);
    const [sortedWriteAlarms, setSortedWriteAlarms] = useState([]);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const isMounted = useRef(true);
    const template = `<p style="text-align:center;">
    <strong>CLICK HERE AND EDIT THIS TEMPLATE</strong>
</p>
<p>
    <span style="background-color:transparent;color:#000000;">Dear ___________________________,</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">Prayer: ______________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">Affirmation:&nbsp; Thank you for _______________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">My answer to the above dialogue question is ______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">My feeling about my answer to the above dialogue question is ______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">If my ________________________ feeing were a physical sensation it would be like ______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">On a scale of 1 to 10 my ________________________ feeling is a _____________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">A color that comes to mind describing my feeling is ________________________ like</span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;">______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">If my ________________________ feeling were something in nature, it would be like ______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">An animal that I am thinking of that reminds me of my feeling is a&nbsp;</span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;">______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">A shared memory that helps describe my ________________________ feeling is when we ______________________________________________________________________________________________</span>
</p>
<p>
    <br>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#000000;">Thank you for sharing with me today.</span>
</p>
<p>
    <br>
    <span style="background-color:transparent;color:#000000;">Love,</span>
</p>`;

    const timerRef = useRef(null);
    useImperativeHandle(ref, () => ({
        stopTimer() {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }
        },
    }));

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Get about the time information
    useEffect(() => {
        const loadTimers = async () => {
            if (!cookieAlive()) return;

            try {
                const response = await axios.post(API_URLS.TIMERS_INFO, {
                    email: cookieAlive(),
                });

                if (response.data.success) {
                    const timers = response.data.data;
                    if (timers.write_time) {
                        setLimitData(parseInt(timers.write_time) * 60);
                    }

                    if (timers.write_alarm && timers.write_alarm.length > 0) {
                        const sortedAlarms = timers.write_alarm.sort((a, b) => {
                            const timeA = a.unit === "m" ? a.time * 60 : a.time;
                            const timeB = b.unit === "m" ? b.time * 60 : b.time;
                            return timeA - timeB;
                        });

                        let tempSortedAlarmData = [];
                        for (let i = 0; i < sortedAlarms.length; i++) {
                            if (sortedAlarms[i].enabled === true) {
                                if (sortedAlarms[i].unit === "s") {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time
                                    );
                                } else {
                                    tempSortedAlarmData.push(
                                        sortedAlarms[i].time * 60
                                    );
                                }
                            }
                        }

                        setSortedWriteAlarms(tempSortedAlarmData);
                    }
                } else {
                    if (isMounted.current) {
                        toast({
                            title: "Timer Information",
                            description: `${response.data.message}.`,
                            bg: "blue",
                            color: "white",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadTimers();
    }, [cookieAlive, toast]);

    // Set the timer event
    useEffect(() => {
        if (limitTime === 0 || !addFlag) return; // Check addFlag
        const interval = setInterval(() => {
            setMakeTimeEllapsed((prevTime) => {
                if (prevTime >= limitTime) {
                    clearInterval(interval);

                    if (isMounted.current) {
                        setTimeIsUp(true);

                        // Move toast call to a useEffect
                        setTimeout(() => {
                            toast({
                                title: "Time's up!",
                                description: "Writing time is up.",
                                duration: 3000,
                                isClosable: true,
                                bg: "blue",
                                color: "white",
                            });
                        }, 0);
                    }

                    return limitTime;
                }

                const newTime = prevTime + 1;
                if (sortedWriteAlarms.includes(newTime)) {
                    const audio = new Audio("./audio/alarm.mp3");
                    audio.play();
                }
                return newTime;
            });
        }, 1000);

        timerRef.current = interval;

        return () => clearInterval(interval);
    }, [limitTime, sortedWriteAlarms, toast, addFlag]); // Add addFlag to dependencies

    // const handleChangeLetter = (event) => {
    //     setContent(event.target.value);
    // };

    return (
        <Container h={"100%"} m={0} w={'full'} display='table'>
            <VStack
                gap={3}
                h={"100%"}
            >
                <Text
                    fontSize="md"
                    w={"full"}
                    textAlign={"center"}
                    fontWeight={600}
                >
                    {feelingData &&
                        feelingData.length > 0 &&
                        feelingData.map((feeling) => feeling.text).join(", ")}
                </Text>
                <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
                    <CKEditor
                        editor={ClassicEditor}
                        config={
                            {
                                plugins: [
                                    Essentials,
                                    Bold,
                                    Italic,
                                    Paragraph,
                                    Font,
                                    Image,
                                    ImageToolbar,
                                    ImageCaption,
                                    ImageStyle,
                                    ImageResize,
                                    ImageInsert,
                                    List,
                                    Indent,
                                    IndentBlock,
                                    Alignment,
                                    Link,
                                    SimpleUploadAdapter,
                                    AutoImage,
                                    FileRepository,
                                    SourceEditing,
                                ],
                                toolbar: {
                                    items: [
                                        'undo',
                                        'redo',
                                        '|',
                                        '|',
                                        'fontfamily',
                                        'fontsize',
                                        'fontColor',
                                        'fontBackgroundColor',
                                        '|',
                                        'bold',
                                        'italic',
                                        '|',
                                        'bulletedList',
                                        'numberedList',
                                        'outdent',
                                        'indent',
                                        'alignment',
                                        'link',
                                        // 'uploadImage',
                                        'sourceEditing',
                                    ],
                                    shouldNotGroupWhenFull: true,
                                },
                                image: {
                                    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
                                },
                            }
                        }
                        data={content ? content : template}
                        onChange={(event, editor) => {
                            setContent(editor.getData());
                        }}
                        disabled={timeIsUp || !addFlag}
                        w={"full"}
                    />
                </CKEditorContext>
                <VStack w={"full"}>
                    <HStack>
                        <Text>
                            {`${String(
                                Math.floor(makeTimeEllapsed / 60)
                            ).padStart(2, "0")}:${String(
                                makeTimeEllapsed % 60
                            ).padStart(2, "0")}`}
                            {`/`}
                            {`${String(Math.floor(limitTime / 60)).padStart(
                                2,
                                "0"
                            )}:${String(limitTime % 60).padStart(2, "0")} mins`}
                        </Text>
                    </HStack>

                    <HStack w={"full"}>
                        <Text fontSize="md">Time: </Text>
                        <Progress
                            w={"full"}
                            max={limitTime}
                            value={makeTimeEllapsed}
                            colorScheme="blue"
                            size="xs"
                        />
                    </HStack>
                </VStack>
            </VStack>
        </Container>
    );
});

export default DialogueLetter;
