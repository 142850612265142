import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	FormControl,
	FormLabel,
	Input,
	VStack,
	useToast,
	HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { FaSave } from "react-icons/fa";
import { useCookies } from 'react-cookie';
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../providers/AppProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { API_URLS } from "../../Constants";

function ContactInfo() {

	const toast = useToast();

	const { cookieAlive } = useContext(AuthContext);

	const { contactInfo, setContactInfo } = useContext(AppContext);

	const [isSaving, setIsSaving] = useState(false);

	const [cookies, setCookie] = useCookies(['dd_token', 'dd_user_id', 'dd_user_email', 'dd_spouse_email', 'dd_user_level']);

	const validateInfo = (contact) => {
		if (!contact) {
			return true;
		} else {
			const keys = Object.keys(contact);
			if (keys.length < 6) return false;
			for (let i = 0; i < keys.length; i++) {
				if (!contact[keys[i]] || contact[keys[i]] === "") {
					return false;
				}
			}
			return true;
		}
	};

	useEffect(() => {
		const loadContact = async () => {
			if (!cookieAlive()) return;
			try {
				const response = await axios.post(API_URLS.CONTACT_INFO, {
					email: cookieAlive(),
				});
				if (response.data.success) {
					const contact = response.data.data.contact;
					setContactInfo(contact);
					
					if (cookies['dd_user_email'] === contact?.wEmail)
						setCookie("dd_spouse_email", contact?.hEmail);
					if (cookies['dd_user_email'] === contact?.hEmail)
						setCookie("dd_spouse_email", contact?.wEmail);
				} else {
					toast({
						title: "Contact Information",
						description: `${response.data.message}.`,
						bg: 'blue',
						color: 'white',
						duration: 3000,
						isClosable: true,
					});
				}
			} catch (e) {
				console.error(e);
			}
		};
		loadContact();
	}, []);

	const handleSave = useCallback(async () => {
		try {
			if (!validateInfo(contactInfo)) {
				toast({
					title: "Contact Information",
					description: `Please check the input fields.`,
					duration: 3000,
					isClosable: true,
					bg: 'blue',
					color: 'white'
				});
				return;
			}
			setIsSaving(true);
			const response = await axios.post(API_URLS.CONTACT_UPDATE, {
				loginEmail: cookies['dd_user_email'],
				...contactInfo
			});

			if (response.data.success) {
				if (cookies['dd_user_email'] === contactInfo?.wEmail)
					setCookie("dd_spouse_email", contactInfo?.hEmail);
				if (cookies['dd_user_email'] === contactInfo?.hEmail)
					setCookie("dd_spouse_email", contactInfo?.wEmail);

				toast({
					title: "Contact Information",
					description: `${response.data.message}.`,
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Contact Information",
					description: `${response.data.message}.`,
					bg: 'blue',
					color: 'white',
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsSaving(false);
		}
	});

	return (
		<Card minH={"600px"} w={'full'}>
			<CardHeader textAlign={'center'} fontSize={'18px'} fontWeight={600}>Husband Information</CardHeader>
			<CardBody>
				<VStack>
					<HStack w={'full'}>
						<FormControl isRequired>
							<FormLabel>First Name</FormLabel>
							<Input
								type="text"
								placeholder=""
								
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										hFirstName: e.target.value
									})
								}}
								defaultValue={contactInfo['hFirstName']}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Last Name</FormLabel>
							<Input
								type="text"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										hLastName: e.target.value
									})
								}}
								defaultValue={contactInfo['hLastName']}
							/>
						</FormControl>
					</HStack>
					<HStack w={'full'}>
						<FormControl isRequired>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										hEmail: e.target.value
									})
								}}
								defaultValue={contactInfo['hEmail']}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Phone Number</FormLabel>
							<Input
								type="text"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										hNumber: e.target.value
									})
								}}
								defaultValue={contactInfo['hNumber']}
							/>
						</FormControl>
					</HStack>
				</VStack>
			</CardBody>
			<CardHeader textAlign={'center'} fontSize={'18px'} fontWeight={600}>Wife Information</CardHeader>
			<CardBody>
				<VStack>
					<HStack w={'full'}>
						<FormControl isRequired>
							<FormLabel>First Name</FormLabel>
							<Input
								type="text"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										wFirstName: e.target.value
									})
								}}
								defaultValue={contactInfo['wFirstName']}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Last Name</FormLabel>
							<Input
								type="text"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										wLastName: e.target.value
									})
								}}
								defaultValue={contactInfo['wLastName']}
							/>
						</FormControl>
					</HStack>
					<HStack w={'full'}>
						<FormControl isRequired>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										wEmail: e.target.value
									})
								}}
								defaultValue={contactInfo['wEmail']}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Phone Number</FormLabel>
							<Input
								type="text"
								placeholder=""
								onChange={(e) => {
									setContactInfo({
										...contactInfo,
										wNumber: e.target.value
									})
								}}
								defaultValue={contactInfo['wNumber']}
							/>
						</FormControl>
					</HStack>
				</VStack>
			</CardBody>
			<CardFooter>
				<Button
					w="full"
					colorScheme="blue"
					leftIcon={<FaSave />}
					onClick={() => { handleSave() }}
					isLoading={isSaving}
				>
					Save
				</Button>
			</CardFooter>

		</Card>
	);
}

export default ContactInfo;
