import {
    Card,
    CardBody,
    CardHeader,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URLS } from "../../Constants";
import axios from "axios";
import { ClassicEditor, Alignment, Bold, Essentials, Italic, Paragraph, Link, AutoLink, Font, Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert, List, Indent, IndentBlock } from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../ckeditor-styles.css'
function Feelings(props) {
    const [feelings, setFeelings] = useState({});
    const toast = useToast();
    useEffect(() => {
        const loadFeelings = async () => {
            try {
                const response = await axios.get(API_URLS.MATERIAL_FEELINGS);
                if (response.data.success) {
                    setFeelings(response.data.data);
                } else {
                    toast({
                        title: "Feelings",
                        description: `${response.data.message}.`,
                        bg: 'blue',
                color: 'white',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };
        loadFeelings();


    }, [toast]);
    return (
        <Card minH={"600px"} w={"full"}>
            <CardHeader>{props.title}</CardHeader>
            <CardBody>
                <Card w={'full'}>
                    <CardBody w={'full'}>
                        {feelings[0] && feelings[0].feelings && (
                            <CKEditor
                                editor={ClassicEditor}
                                data={feelings[0]['feelings']}
                                config={{
                                    plugins: [
                                        Essentials, Bold, Italic, Paragraph, Font,
                                        Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageInsert,
                                        List, Indent, IndentBlock, Alignment, Link, AutoLink
                                    ],
                                }}
                                disabled
                            />
                        )}
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    );
}

export default Feelings;
