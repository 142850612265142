import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import {
    VStack,
    Button,
    useToast,
    HStack,
    Card,
    CardBody,
    Text,
    CardHeader,
} from "@chakra-ui/react";
import moment from "moment";
import { API_URLS } from "../Constants";
import { AuthContext } from "../providers/AuthProvider";
import HistoryItem from "../components/history/historyItem";
import { useCookies } from "react-cookie";
import { DatePicker } from "antd";
import dayjs from "dayjs";

function History() {
    const navigate = useNavigate();
    const toast = useToast();
    const { cookieAlive } = useContext(AuthContext);
    const [appointment, setAppointment] = useState(null);
    const [curDate, setCurDate] = useState(
        moment().format("YYYY-MM-DD").toString()
    );
    const [notify, setNotify] = useState("No content or Not shared");
    const [cookies, setCookie] = useCookies();
    const [dateOpen, setDateOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState("husband");
    const [historyDates, setHistoryDates] = useState([]);
    const style = {
        border: `1px solid #3182ce`,
        borderRadius: "50%",
    };
    useEffect(() => {
        if (!cookieAlive()) {
            navigate("/login");
        }

        getHistoryDates();
        handleHusband();
    }, [cookieAlive, navigate]);

    const getHistoryDates = async () => {
        try {
            let formattedDate = moment().format("YYYY-MM-DD");

            const response = await axios.get(API_URLS.DIALOGUE_HISTORY_DATES, {
                params: {
                    email: cookieAlive(),
                    spouseEmail: cookies["dd_spouse_email"],
                    date: formattedDate,
                },
            });
            if (response.data.success) {
                setHistoryDates(response.data.data);
            } else {
                toast({
                    title: "History",
                    description: `${response.data.message}.`,
                    bg: "blue",
                    color: "white",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const cellRender = (current, info) => {
        if (info.type !== "date") {
            return info.originNode;
        }

        if (typeof current === "number" || typeof current === "string") {
            return <div className="ant-picker-cell-inner">{current}</div>;
        }

        if (historyDates.length > 0) {
            
            const t = dayjs(current).format("YYYY-MM-DD").toString();
            if (historyDates.includes(t))
                return (
                    <div
                        className="ant-picker-cell-inner"
                        style={style}
                    >
                        {current.date()}
                    </div>
                );
        }
        return <div className="ant-picker-cell-inner">{current.date()}</div>;
    };

    const getAppointment = async (date) => {
        try {
            const response = await axios.post(
                API_URLS.DIALOGUE_GET_SHARE_APPT,
                {
                    date: date,
                    email: cookies["dd_user_email"],
                }
            );
            if (response.data.data) {
                console.log(response.data)
                setAppointment(response.data.data);
            } else {
                setNotify(response.data.message);
                setAppointment(null);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleHusband = useCallback(() => {
        setSelectedRole("husband");
        getAppointment(curDate);
    });

    const handleWife = useCallback(() => {
        setSelectedRole("wife");
        getAppointment(curDate);
    });

    const handleChangeDate = (e) => {
        setDateOpen((p) => !p);
        const dateString = dayjs(e).format("YYYY-MM-DD").toString();
        setCurDate(dateString);
        setSelectedRole("husband");
        getAppointment(dateString);
    };

    return (
        <VStack spacing={4} p={8}>
            <VStack w={"full"} maxW={"665px"}>
                <Button
                    colorScheme="blue"
                    w={"full"}
                    position={"relative"}
                    onClick={() => {
                        setDateOpen(true);
                    }}
                >
                    {curDate}
                </Button>
                <DatePicker
                    id="historyDatePicker"
                    format={"YYYY-MM-DD"}
                    maxDate={dayjs()}
                    style={{
                        fontSize: "1rem",
                        width: "100%",
                        height: "1px",
                        textAlign: "center",
                        visibility: "hidden",
                    }}
                    open={dateOpen}
                    onChange={(e) => {
                        handleChangeDate(e);
                    }}
                    cellRender={cellRender}
                />
                <Card w={"full"}>
                    <CardHeader>
                        <HStack justifyContent={"center"} w={"full"}>
                            <Button
                                colorScheme={
                                    selectedRole === "husband" ? "blue" : "gray"
                                }
                                px={8}
                                onClick={(e) => {
                                    handleHusband();
                                }}
                            >
                                Husband
                            </Button>
                            <Button
                                colorScheme={
                                    selectedRole === "wife" ? "blue" : "gray"
                                }
                                px={8}
                                onClick={(e) => {
                                    handleWife();
                                }}
                            >
                                Wife
                            </Button>
                        </HStack>
                    </CardHeader>
                    <CardBody>
                        {appointment && (
                            <HistoryItem data={appointment} role={selectedRole} />
                        )}
                        {!appointment && <Text w={'full'} textAlign='center'>No content or Not shared</Text>}
                    </CardBody>
                </Card>
            </VStack>
        </VStack>
    );
}

export default History;
