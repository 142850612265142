import { createContext } from "react";
import { useState } from "react";

export const DialogueContext = createContext();

const DialogueProvider = ({ children }) => {
    const [writingTime, setWritingTime] = useState();
    const [sharingTime, setSharingTime] = useState();
    const [emotion, setEmotion] = useState("");
    const [feelingData, setFeelingData] = useState([]);
    const [content, setContent] = useState("");
    const [feelingCategory, setFeelingCategory] = useState([]);
    const [addFlag, setAddFlag] = useState(false);
    const [shareFlag, setShareFlag] = useState(false);
    const [makeTimeEllapsed, setMakeTimeEllapsed] = useState(0);
    const [shareTimeEllapsed, setShareTimeEllapsed] = useState(0);

    const onNext = (step) => {
        if (step === 1) {
            setFeelingData([]);
        } else if (step === 2) {
            if(feelingData.length === 0) {
                return {
                    result: false,
                    msg: "You have to select at least 1 feelings.",
                };
            }
        } else if (step === 3) {
            if (content.length === 0) {
                return {
                    result: false,
                    msg: "There is no input information. If you are recording, then it has to be saved. Please click Save button.",
                };
            }
        }

        return {
            result: true,
            msg: "Success",
        };
    };

    const onPrev = (step) => {
        if ( step === 1 ) {
        } else if (step === 2) {
        } else if (step === 3) {            
        }

        return {
            result: true,
            msg: "Success",
        };
    };

    return (
        <DialogueContext.Provider
            value={{
                onNext,
                onPrev,
                emotion,
                setEmotion,
                feelingData,
                setFeelingData,
                content,
                setContent,
                feelingCategory,
                setFeelingCategory,
                addFlag,
                setAddFlag,
                shareFlag,
                setShareFlag,
                makeTimeEllapsed,
                setMakeTimeEllapsed,
                sharingTime,
                setSharingTime,
                writingTime,
                setWritingTime,
                shareTimeEllapsed,
                setShareTimeEllapsed
            }}
        >
            {children}
        </DialogueContext.Provider>
    );
};

export default DialogueProvider;
